
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NavigationLayout from "components/layout/NavigationLayout";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import { useRouter } from "next/router";
import styles from "pages/rewards/index.module.scss";
import ArrowRight from "images/icons/arrow_right_757575_16.svg";
import PointIcon from "images/static/point_icon.png";
import PushIcon from "images/static/push_icon.png";
import OfferwallIcon from "images/static/offerwall_icon.png";
import Image from "next/image";
import UserContext from "context/AuthContext";
import { useContext, useEffect, useRef, useState } from "react";
import { customAlert, customConfirm, isApp, isIOS, numberWithComma, postRNMessage } from "common/utils";
import { Axios } from "api";
import { captureException, captureMessage } from "@sentry/nextjs";
import Loading from "components/Loading";
import { MESSAGE_TYPE_OFFERWALL_CLOSED, MESSAGE_TYPE_OFFERWALL_POINTS, REVERSE_MESSAGE_TYPE_HAS_PUSH_PERMISSION, REVERSE_MESSAGE_TYPE_OPEN_OFFERWALL_PAGE, REVERSE_MESSAGE_TYPE_REFRESH_OFFERWALL_POINTS, } from "pages/_app";
import { offerwallPrivacyPolicyRoute, pointsRoute } from "common/const";
import ModalContext from "context/ModalContext";
import MainCouponModal from "components/modal/MainCouponModal";
import useNotiSubscription from "hooks/useNotiSubscription";
import Toggle from "components/toggle";
import { completeReward } from "common/rewards";
import { redirectToApp, redirectToLogin } from "common/redirect";
import BottomModal from "components/BottomModal";
import CheckBoxIcon from "images/icons/check_circle_green.svg";
import FullButton from "components/buttons/FullButton";
import { toast } from "react-toastify";
import Link from "next/link";
import semver from "semver";
import SubscriptionToggle from "components/toggle/SubscriptionToggle";
import useTranslation from "next-translate/useTranslation";
import Trans from "next-translate/Trans";
export const REWARD_KEY_PALETTE_LIKE_5 = 'daily_palette_like5';
export const REWARD_KEY_PRODUCT_LIKE_3 = 'daily_product_like3';
export const REWARD_KEY_EVENT_LIST_CHECK = 'daily_eventlist_check';
export const REWARD_KEY_SPECIAL_OFFER_CHECK = 'daily_specialoffer_check';
export const REWARD_KEY_HEME_TICKET_CHECK = 'daily_hemeticket_check';
export const REWARD_KEY_NEW_BRAND_CHECK = 'daily_new_brand_check';
export const REWARD_KEY_LOGIN = 'daily_login';
export const REWARD_KEY_DAILY_PICK_CHECK = 'daily_daily_pick_check';
export const REWARD_KEY_REWARD_NOTI_AGREED = 'reward_noti_agreed';
const RewardCard = (props) => {
    const { rewardKey, title, description, imageUrl, isReceived, link, onClick } = props;
    const user = useContext(UserContext).user;
    const router = useRouter();
    const { t } = useTranslation('rewards-index');
    const handleRewardCardClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("RewardListCardClick", {
            key: rewardKey,
            is_received: isReceived
        });
        if (onClick) {
            onClick();
            return;
        }
        if (!link)
            return;
        if (!user) {
            customConfirm(t('Rewards.loginConfirm.title'), t('Rewards.loginConfirm.confirm'), t('Rewards.loginConfirm.cancel'), () => {
                redirectToLogin(router, true);
            }, () => {
            });
        }
        else {
            router.push(link);
        }
    };
    return (<a className={classNames(styles.rewardCardContainer)} onClick={handleRewardCardClick}>
            <div className={styles.rewardImageWrapper}>
                <Image unoptimized src={imageUrl} className={styles.rewardIcon} objectFit="cover" layout="fill"/>
                {isReceived &&
            <div className={styles.rewardImageOverlay}/>}
            </div>
            <div className={styles.rewardInfoWrapper}>
                <span className={styles.rewardCardTitle}>{title}</span>
                <span className={styles.rewardCardDescription}>{description}</span>
            </div>
            {isReceived &&
            <div className={styles.rewardReceived}>
                    {t('Rewards.rewardReceived')}
                </div>}
        </a>);
};
const REWARD_NOTI_TOPIC_KEY = 'custom_topic@daily_reward';
const Rewards = () => {
    const router = useRouter();
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});
    const modalContext = useContext(ModalContext);
    const pushModalInfo = (modalContext.couponModalInfo ?
        modalContext.couponModalInfo.modal_list.filter(e => e.identifier && e.identifier.includes('app_install_modal_point'))[0] :
        null);
    const pushModalOpenable = pushModalInfo && !(pushModalInfo.coupon_molds.length > 0 && pushModalInfo.coupon_molds.filter(e => e.is_duplication_allowed || !modalContext.receivedCouponMoldIdsRef.current.includes(e.id)).length === 0);
    const { notiTypeAndKeyToIsSubscribedAndPushOn, toggleSubscription, overrideIsSubscribed } = useNotiSubscription({ [REWARD_NOTI_TOPIC_KEY]: false });
    const [offerwallPoints, setOfferwallPoints] = useState(null);
    const lastOfferwallRewardIdRef = useRef(0);
    const loadRewardList = async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get('/v1/rewards/');
            if (res.status < 400) {
                setData(res.data);
                lastOfferwallRewardIdRef.current = res.data.last_offerwall_reward ? res.data.last_offerwall_reward.id : 0;
                refreshOfferwallPoints(res.data.user_identifier);
                overrideIsSubscribed({ [REWARD_NOTI_TOPIC_KEY]: res.data.is_reward_noti_subscribed });
            }
            else {
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const refreshOfferwallPoints = (identifier) => {
        const userIdentifier = identifier || 'unauthorized';
        if (isApp()) {
            postRNMessage({
                type: REVERSE_MESSAGE_TYPE_REFRESH_OFFERWALL_POINTS,
                data: {
                    identifier: userIdentifier
                }
            });
        }
    };
    const checkPointReceived = async () => {
        try {
            const res = await Axios.get('v1/offer-wall/last-record/');
            if (res.status < 400) {
                const lastReward = res.data.reward;
                if (!lastReward)
                    return;
                if (lastOfferwallRewardIdRef.current && lastReward.id > lastOfferwallRewardIdRef.current) {
                    lastOfferwallRewardIdRef.current = lastReward ? lastReward.id : 0;
                    customAlert(t('Rewards.pointEarnedAlert', { point: lastReward.point }), t('Rewards.confirm'), () => { });
                }
            }
        }
        catch (e) { }
    };
    const [isOfferwallAgreeModalOpen, setIsOfferwallAgreeModalOpen] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            checkPointReceived();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, []);
    useEffect(() => {
        const offerwallCloseListener = (messageEvent) => {
            let message = null;
            try {
                message = JSON.parse(messageEvent.data);
            }
            catch (e) {
                return;
            }
            if (message.type === MESSAGE_TYPE_OFFERWALL_CLOSED) {
                loadTotalPoints();
                refreshOfferwallPoints(data.user_identifier);
                checkPointReceived();
            }
            else if (message.type === MESSAGE_TYPE_OFFERWALL_POINTS) {
                setOfferwallPoints(message.data.point);
            }
        };
        if (isApp()) {
            if (isIOS()) {
                window.addEventListener('message', offerwallCloseListener);
                return () => {
                    window.removeEventListener('message', offerwallCloseListener);
                };
            }
            else {
                document.addEventListener('message', offerwallCloseListener);
                return () => {
                    document.removeEventListener('message', offerwallCloseListener);
                };
            }
        }
    }, [data]);
    const loadTotalPoints = async () => {
        try {
            const res = await Axios.get('/v1/total-points/');
            if (res.status < 400) {
                userContext.setPoint(res.data.total_point);
            }
            else {
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            captureException(e);
        }
    };
    const handlePointSectionClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track("RewardListMyPointClick");
        if (!user) {
            customConfirm(t('Rewards.loginConfirm.title'), t('Rewards.loginConfirm.confirm'), t('Rewards.loginConfirm.cancel'), () => {
                redirectToLogin(router, true);
            }, () => {
            });
        }
        else {
            router.push(pointsRoute);
        }
    };
    const rewardList = data.reward_list || [];
    const receivedRewardIds = data.received_reward_ids || [];
    useEffect(() => {
        loadRewardList();
        loadTotalPoints();
    }, []);
    const totalPoints = rewardList.reduce((acc, cur) => {
        return acc + cur.point;
    }, 0);
    const isSubscribed = notiTypeAndKeyToIsSubscribedAndPushOn[REWARD_NOTI_TOPIC_KEY];
    const toggleRewardNotiSubscription = () => {
        toggleSubscription(REWARD_NOTI_TOPIC_KEY);
    };
    useEffect(() => {
        if (isSubscribed) {
            completeReward(router, REWARD_KEY_REWARD_NOTI_AGREED, t('Rewards.rewardNotiBonus', { point: '{point}' }), t('Rewards.rewardNotiDaily', { point: '{point}' }), null);
        }
    }, [isSubscribed]);
    const handleOfferwallAgreeClick = async () => {
        try {
            const res = await Axios.post('/v1/offer-wall/privacy-policy-agree/', {
                is_offerwall_privacy_policy_agreed: true
            });
            if (res.status < 400) {
                setData(oldData => {
                    return {
                        ...oldData,
                        is_offerwall_privacy_policy_agreed: res.data.is_offerwall_privacy_policy_agreed
                    };
                });
                setIsOfferwallAgreeModalOpen(false);
                postRNMessage({
                    type: REVERSE_MESSAGE_TYPE_OPEN_OFFERWALL_PAGE,
                    data: {
                        identifier: data.user_identifier
                    }
                });
            }
            else {
                toast.info(t('Rewards.errorToast'));
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            toast.info(t('Rewards.errorToast'));
            captureException(e);
        }
    };
    const [currentAppVersion, setCurrentAppVersion] = useState('0.0.0');
    useEffect(() => {
        const appVersion = typeof window !== 'undefined' && isApp() ? (window.RNAppVersion || '0.0.0') : '0.0.0';
        setCurrentAppVersion(appVersion);
    }, []);
    const { t } = useTranslation('rewards-index');
    return (<div className={utilStyles.pageContainer}>
            <BottomModal preventBackgroundClose={false} isOpen={isOfferwallAgreeModalOpen} close={() => {
            setIsOfferwallAgreeModalOpen(false);
        }}>
                <div className={styles.offerwallAgreeModalContainer}>
                    <CheckBoxIcon viewBox="0 0 20 20" width={52} height={52}/>
                    <div className={styles.agreeModalTitle}>{t('Rewards.offerwallAgreeModal.title')}</div>
                    <div className={styles.agreeModalSubtitle}>
                        {t('Rewards.offerwallAgreeModal.subtitle')}
                    </div>
                    <Link href={offerwallPrivacyPolicyRoute}>
                        <a target={"_blank"} className={styles.agreeContentLink}>
                            <span>{t('Rewards.offerwallAgreeModal.privacyAgreement')}</span>
                            <ArrowRight viewBox="0 0 16 16"/>
                        </a>
                    </Link>
                    <div className={styles.agreeButtonContainer}>
                        <div style={{ flex: 1 }}>
                            <FullButton white height={48} fontSize={16} title={t('Rewards.offerwallAgreeModal.cancel')} onClick={() => {
            setIsOfferwallAgreeModalOpen(false);
        }}/>
                        </div>
                        <div style={{ flex: 1.43 }}>
                            <FullButton height={48} fontSize={16} title={t('Rewards.offerwallAgreeModal.agree')} onClick={() => {
            handleOfferwallAgreeClick();
        }}/>
                        </div>
                    </div>
                </div>
            </BottomModal>
            <div className={classNames(utilStyles.newSidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                <TitleBar title={t('Rewards.title')} isBack close={() => router.back()}/>
            </div>
            <div className={utilStyles.newSidePadding}>
                <div className={styles.totalPointContainer}>
                    <div className={styles.totalPointContainerElements} onClick={handlePointSectionClick}>
                        <div className={styles.totalPointWrapper}>
                            <div className={styles.pointIconWrapper}>
                                <Image className={styles.pointIcon} unoptimized src={PointIcon.src} layout="fill" objectFit="cover"/>
                            </div>
                            <span className={styles.totalPoints}>{numberWithComma(user?.point || 0)} P</span>
                            <div className={styles.gainablePoints}>
                                {t('Rewards.dailyEarning', { totalPoints: totalPoints + 10 })}
                            </div>
                        </div>
                        <ArrowRight viewBox="0 0 16 16" className={styles.totalPointArrow}/>
                    </div>
                    <div>
                        <span className={styles.rewardExpires}>{t('Rewards.pointExpires')}</span>
                    </div>
                </div>
                {isLoading ?
            <Loading />
            :
                <>
                            {pushModalOpenable && pushModalInfo &&
                        <div className={styles.rewardCardWrapper}>
                                    <RewardCard rewardKey={"push_from_app"} title={t('Rewards.pushReward.title')} description={t('Rewards.pushReward.description')} imageUrl={PushIcon.src} onClick={() => {
                                if (!isApp()) {
                                    customConfirm(t('Rewards.appConfirm.title'), t('Rewards.appConfirm.confirm'), t('Rewards.appConfirm.cancel'), () => {
                                        redirectToApp();
                                    }, () => {
                                    });
                                }
                                else {
                                    modalContext.openModal(() => {
                                        return (<MainCouponModal modalInfo={pushModalInfo} close={modalContext.closeModal}/>);
                                    });
                                }
                            }}/>
                                </div>}
                            {(rewardList.map(e => {
                        return (<div key={e.id} className={styles.rewardCardWrapper}>
                                                <RewardCard rewardKey={e.key} title={e.title} description={t('Rewards.rewardDescription', { point: e.point })} imageUrl={e.image_url} link={e.link} isReceived={receivedRewardIds.includes(e.id)}/>
                                            </div>);
                    }))}
                            {(isApp() && semver.gte(currentAppVersion, '1.0.10') && !!data.user_identifier) &&
                        <div className={styles.rewardCardWrapper}>
                                    <RewardCard rewardKey={"offerwall_reward"} title={t('Rewards.offerwallReward.title')} description={offerwallPoints ? t('Rewards.offerwallReward.description', { points: numberWithComma(offerwallPoints) }) : t('Rewards.offerwallReward.defaultDescription')} imageUrl={OfferwallIcon.src} onClick={() => {
                                typeof mixpanel !== 'undefined' && mixpanel.track('OfferwallRowClick');
                                const open = () => {
                                    if (!user) {
                                        customConfirm(t('Rewards.loginConfirm.title'), t('Rewards.loginConfirm.confirm'), t('Rewards.loginConfirm.cancel'), () => {
                                            redirectToLogin(router, true);
                                        }, () => {
                                        });
                                    }
                                    else if (!!data.user_identifier) {
                                        if (!data.is_offerwall_privacy_policy_agreed) {
                                            setIsOfferwallAgreeModalOpen(true);
                                            return;
                                        }
                                        postRNMessage({
                                            type: REVERSE_MESSAGE_TYPE_OPEN_OFFERWALL_PAGE,
                                            data: {
                                                identifier: data.user_identifier
                                            }
                                        });
                                    }
                                };
                                if (isIOS())
                                    open();
                                else {
                                    customAlert(t('Rewards.offerwallAlert.title'), t('Rewards.offerwallAlert.confirm'), () => {
                                        open();
                                    });
                                }
                            }}/>
                                </div>}
                        </>}
                <SubscriptionToggle isSubscribed={isSubscribed} handleSubscriptionToggle={toggleRewardNotiSubscription} title={isSubscribed ?
            <Trans i18nKey="rewards-index:Rewards.subscriptionToggle.subscribed.title" components={[<span />]}/> :
            <Trans i18nKey="rewards-index:Rewards.subscriptionToggle.unsubscribed.title" components={[<span />]}/>} description={isSubscribed ?
            t('Rewards.subscriptionToggle.subscribed.description') :
            t('Rewards.subscriptionToggle.unsubscribed.description')} iconImageSource={PointIcon.src}/>
            </div>
        </div>);
};
Rewards.getLayout = NavigationLayout;
export default Rewards;

    async function __Next_Translate__getStaticProps__195d0eab111__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/rewards/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d0eab111__ as getStaticProps }
  