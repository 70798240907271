import styles from "components/cards/checkoutPointCard.module.scss";
import utilStyles from "styles/utils.module.scss";
import FullButton from "components/buttons/FullButton";
import classNames from "classnames";
import useTranslation from 'next-translate/useTranslation';
import {useEffect} from "react";

export default function CheckoutPointCard (props) {
    const {t} = useTranslation('components-cards-CheckoutPointCard');
    const {value, maxUsablePoints, minUsablePoints, isReservation, currency, disable, availablePoints} = props;
    const onChange = props.onChange || function () {};
    const isDisabled = maxUsablePoints < minUsablePoints || maxUsablePoints === 0 || isReservation || disable || availablePoints === 0;

    const onUseAllPointsClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPointCardUseAllPointsClick');
        onChange(maxUsablePoints);
    };

    const inputChange = (arg) => {
        const number = Number.parseInt(arg.target.value);
        if (Number.isInteger(number) || arg.target.value === '') {
            if (arg.target.value === '') {
                onChange('');
            } else if (number <= maxUsablePoints) {
                onChange(number);
            } else {
                onChange(maxUsablePoints);
            }
        }
    };

    useEffect(() => {
        if (!Number.isInteger(value)) {
            return;
        }

        if (value > maxUsablePoints) {
            onChange(maxUsablePoints);
        }
    }, [maxUsablePoints]);

    return (
        <div className={classNames(utilStyles.flexRow)}>
            <input
                type="text"
                value={value}
                disabled={isDisabled}
                className={styles.input}
                placeholder={
                    !isReservation ?
                        t('CheckoutPointCard.maxPointsUsable', { points: maxUsablePoints, currency })
                        :
                        t('CheckoutPointCard.noPointsForHemeTicket')
                }
                onChange={inputChange}
            />
            {
                !isReservation &&
                <div className={styles.button}>
                    <FullButton
                        fontWeight={600}
                        disabled={isDisabled}
                        lightWhite={isDisabled}
                        onClick={onUseAllPointsClick}
                        height={44}
                        fontSize={15}
                        title={t('CheckoutPointCard.useAll')}
                    />
                </div>
            }
        </div>
    )
};
