import {createContext, useContext, useEffect} from "react";
import UserContext from "context/AuthContext";
import ModalContext from "context/ModalContext";
import {checkIfOpenAllowed, hideForXMinutes} from "common/modalHelper";
import SelectCountryModal, {
    STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL,
    STORAGE_KEY_COUNTRY_MODAL_SKIPPED
} from "components/modal/SelectCountryModal";
import {useRouter} from "next/router";
import {isApp} from "common/utils";
import Cookies from "js-cookie";
import {COUNTRY_CODE_TO_BASE_URL} from "common/country_list";
import {KOREA_COUNTRY_CODE} from "common/i18n";
import {getCountryCodeByUserIp} from "api/localization/i18n";
import {COUNTRY_CODE} from "common/const";

const I18nContext = createContext(undefined);

export let i18nContextRef = {};

export default I18nContext;

const I18nContextWrapper = ({children}) => {
    const context = useContext(I18nContext);

    useEffect(() => {
        i18nContextRef.current = context;
    }, []);

    useEffect(() => {
        i18nContextRef.current = context;
    }, [context]);

    return (
        <>
            {children}
        </>
    )
}


export const I18nContextProvider = ({children}) => {
    const user = useContext(UserContext).user;
    const {openModal, closeModal} = useContext(ModalContext) || {};
    const {locale, query, replace} = useRouter();
    const countryCode = locale.toUpperCase() === 'KO' ? 'KR' : locale.toUpperCase() === 'EN' ? COUNTRY_CODE : locale.toUpperCase();

    const changeCountryCode = (targetCountryCode) => {
        hideForXMinutes(STORAGE_KEY_COUNTRY_MODAL_SKIPPED, 24 * 30 * 6);

        if (countryCode === targetCountryCode.toUpperCase()) {
            return;
        }

        if (typeof window === 'undefined') {
            return;
        }

        const selectedBaseUrl = COUNTRY_CODE_TO_BASE_URL[targetCountryCode];
        const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;

        const previousPathname = window.location.search;
        let newQueries = previousPathname ? `${previousPathname}&saveCountry=true` : '?saveCountry=true';
        let newPathName;

        if (targetCountryCode.toUpperCase() === 'KR') {
            const paths = window.location.pathname ? window.location.pathname.split('/') : [];
            const remainingPath = paths.slice(2).join("/");
            newPathName = `${remainingPath}`;
            Cookies.set("NEXT_COUNTRY", 'KR', {
                expires: 365, domain: siteUrl,
            });
        } else {
            const cookieValue = targetCountryCode.toUpperCase();
            Cookies.set("NEXT_COUNTRY", cookieValue, {
                expires: 365, domain: siteUrl,
            });

            if (window.location.pathname?.split('/')[1]?.length === 2) {
                newPathName = window.location.pathname.replace(
                    `/${window.location.pathname.split('/')[1]}`, targetCountryCode.toLowerCase()
                );
            } else {
                newPathName = `${targetCountryCode.toLowerCase()}${window.location.pathname}`;
            }
        }


        window.location.href = (
            `https://${selectedBaseUrl}/${newPathName}${newQueries}`
        );
    }

    const checkCountryByIpAndOpenSelectCountryModal = async (force = false) => {
        if (isApp()) {
            return;
        }

        if (force) {
            openModal(() => {
                return (
                    <SelectCountryModal
                        close={closeModal}
                        currentCountryCode={countryCode}
                        onSubmit={changeCountryCode}
                    />
                )
            });
            return;
        }

        const flag = !checkIfOpenAllowed(STORAGE_KEY_COUNTRY_MODAL_SKIPPED);
        if (flag) {
            return;
        }

        // if (countryCode === KOREA_COUNTRY_CODE || countryCode.toLowerCase() === 'ko') {
        //     return;
        // }

        const ipCountryCode = await getCountryCodeByUserIp();

        console.log('ipCountryCode', ipCountryCode);

        if (!ipCountryCode || !countryCode || countryCode === ipCountryCode) {
            return;
        }

        if (user) {
            return;
        }

        if (!checkIfOpenAllowed(STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL)) {
            return;
        }

        openModal(() => {
            return (
                <SelectCountryModal
                    close={closeModal}
                    currentCountryCode={countryCode}
                    onSubmit={changeCountryCode}
                />
            )
        });
    };

    const saveCountryCode = () => {
        if (query.saveCountry) {
            hideForXMinutes(STORAGE_KEY_COUNTRY_MODAL_SKIPPED, 24 * 30 * 6);
            // delete query
            const newQuery = {...query};
            delete newQuery.saveCountry;
            replace({query: newQuery});
        }
    };

    useEffect(() => {
        saveCountryCode();
    }, [query]);

    useEffect(() => {
        checkCountryByIpAndOpenSelectCountryModal();
    }, [countryCode]);


    const contextValue = {
        countryCode, checkCountryByIpAndOpenSelectCountryModal, changeCountryCode
    };

    return (
        <I18nContext.Provider value={contextValue}>
            <I18nContextWrapper>
                {children}
            </I18nContextWrapper>
        </I18nContext.Provider>
    )
}
