import styles from "components/modal/SelectCountryModal.module.scss";
import FullButton from "components/buttons/FullButton";
import BottomModal from "components/BottomModal";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import CountryInputSelector from "components/input/CountryInputSelector";
import {hideForXMinutes} from "common/modalHelper";
import {useState} from "react";


export const STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL = 'country_modal_close_until';
export const STORAGE_KEY_COUNTRY_MODAL_SKIPPED = 'country_modal_skipped_flag';
const SelectCountryModal = (props) => {
    const close = props.close || function () {
    };

    const [selectedCountryCode, setSelectedCountryCode] = useState(props.currentCountryCode);
    const handleSubmit = props.onSubmit ? () => {
        props.onSubmit(selectedCountryCode);
        close();
    } : close

    const handleClose = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('SelectCountryModalCloseButtonClick');
        hideForXMinutes(STORAGE_KEY_COUNTRY_MODAL_CLOSE_UNTIL, 120);
        close();
    };

    return (
        <BottomModal isOpen={true} close={close} preventBackgroundClose>
            <div className={styles.selectCountryModalContainer}>
                <div className={styles.selectCountryModalContentContainer}>
                    <div className={styles.selectCountryModalTitle}>
                        Please select your location
                    </div>
                    <div className={styles.countryInputSelectorContainer}>
                        <CountryInputSelector
                            countryCode={selectedCountryCode}
                            onChange={setSelectedCountryCode}
                        />
                    </div>
                    <div
                        className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter, styles.bottomButtonContainer)}
                    >
                        <a className={styles.cancelButton} onClick={handleClose}>
                            <FullButton white title={'Continue'} height={48} fontSize={16}/>
                        </a>
                        <a className={styles.saveButton}>
                            <FullButton
                                title={'Change'} height={48} fontSize={16}
                                onClick={handleSubmit}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </BottomModal>
    )
};

export default SelectCountryModal;
