import utilStyles from "styles/utils.module.scss";
import {useContext, useRef, useState} from "react";
import KakaoIcon from "images/icons/kakao.svg";
import * as Sentry from "@sentry/nextjs";
import Clipboard from "react-clipboard.js";
import styles from "components/ShareButton.module.scss";
import {toast} from "react-toastify";
import CopyIcon from "images/icons/copy.svg";
import CloseIcon from "images/icons/close.svg";
import ShareIcon from "images/icons/share.svg";
import classNames from "classnames";
import {KAKAO_CLIENT_ID} from "common/const";
import Script from "next/script";
import {isApp, isIOS, postRNMessage, useOutsideAlerter} from "common/utils";
import Link from "next/link";
import {v4} from "uuid";
import {REVERSE_MESSAGE_TYPE_SHARE} from "pages/_app";
import PushContext from "context/PushContext";

const ShareButton = (props) => {
    const {children, shareTitle, shareLink, offsetX, offsetY, tailOffsetX, kakaoShareCallback, fullSize} = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const shareModalRef = useRef(null);
    useOutsideAlerter(shareModalRef, () => {
        setTimeout(() => {
            setIsModalOpen(false);
        }, 100);
    });

    const onClick = props.onClick || (() => {});
    const onShare = props.onShare || (() => {});

    return (
        <>
            <Script src="https://developers.kakao.com/sdk/js/kakao.js" onLoad={() => {
                if (!Boolean(Kakao?.isInitialized())) {
                    Kakao.init(KAKAO_CLIENT_ID);
                }
            }} strategy="afterInteractive" />
            <div className={classNames(utilStyles.relative, fullSize ? utilStyles.fullWidth : undefined, fullSize ? utilStyles.fullHeight: undefined)}>
                {
                    isModalOpen &&
                    <div className={styles.shareModal} ref={shareModalRef} style={{left: offsetX, right: offsetX === undefined ? 0 : undefined, top: offsetY}}>
                        <KakaoIcon viewBox="0 0 48 48" className={styles.kakaoIcon} onClick={() => {
                            onShare('kakao');
                            kakaoShareCallback();
                            setIsModalOpen(false);
                        }} />
                        <Clipboard
                            option-text={() => shareLink}
                            onSuccess={() => {
                                onShare('clipboard');
                                toast.info('링크를 복사했어요');
                                setIsModalOpen(false);
                            }}
                        >
                            <CopyIcon viewBox="0 0 40 40" className={styles.copyIcon} />
                        </Clipboard>
                        <CloseIcon viewBox="0 0 20 20" className={styles.closeIcon} onClick={() => {
                            setIsModalOpen(false);
                        }} />
                        <div className={styles.tail} style={{left: tailOffsetX, right: tailOffsetX === undefined ? 2: 0}} />
                    </div>
                }
                <div className={classNames(fullSize ? utilStyles.fullWidth : undefined, fullSize ? utilStyles.fullHeight : undefined)} onClick={async (e) => {
                    const result = await onClick(e);
                    if (result === false) {
                        return;
                    }
                    if (isApp() && !isIOS()) {
                        postRNMessage({
                            type: REVERSE_MESSAGE_TYPE_SHARE,
                            data: {
                                message: shareLink, title: shareTitle, url: shareLink
                            },
                        });
                    } else {
                        if (navigator.share) {
                            navigator.share({
                                title: shareTitle,
                                url: shareLink,
                            });
                        } else {
                            if (!isModalOpen) {
                                setIsModalOpen(prev => !prev);
                            }
                        }
                    }
                }}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default ShareButton;
