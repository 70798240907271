
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import UserContext from "context/AuthContext";
import { Axios } from "api";
import { getLoggingDataFromOptionCount } from "common/utils";
import { getOrderDetailRoute } from "common/const";
import IamportLayout from "components/layout/IamportLayout";
import ErrorPage from "components/ErrorPage";
import Loading from "components/Loading";
import { captureException } from "@sentry/nextjs";
import { trackPixel } from "api/pixel";
import { redirectToLogin } from "common/redirect";
import useTranslation from "next-translate/useTranslation";
import CheckoutInfo from "components/sections/checkout/info/CheckoutInfo";
export const KEY_DELIVERY_MEMO = 'delivery_memo';
export const KEY_PAY_METHOD = 'pay_method';
export const KEY_PG = 'pg';
export const KEY_IS_ESCROW = 'is_escrow';
export const KEY_RECEIVER_PHONE_NUMBER = 'receiver_phone_number';
export const KEY_RECEIVER_NAME = 'receiver_name';
export const KEY_ORDERER_NAME = 'orderer_name';
export const KEY_ORDERER_PHONE_NUMBER = 'orderer_phone_number';
export const KEY_GIFT_RECEIVER_NAME = 'gift_receiver_name';
export const KEY_DELIVERY_NAME = 'delivery_name';
export const KEY_SELECTED_COUPONS = 'selected_coupons';
export const KEY_USED_POINTS = 'used_points';
export const KEY_AGREEMENT_CHECKED = 'agreement_checked';
export const KEY_SUGGEST_BEST_COUPON = 'suggest_best_coupon';
export const KEY_VERIFIED_PHONE_NUMBER = 'verified_phone_number';
export const KEY_POSTAL_CODE = 'postal_code';
export const KEY_ROUGH_ADDRESS = 'rough_address';
export const KEY_DETAIL_ADDRESS = 'detail_address';
export const KEY_ADDRESS_ID = 'address_id';
export const KEY_ORDER_COUNTRY_CODE = 'country_code';
export const KEY_CALLBACK_DOMAIN = 'callback_domain';
//해외 주문 필드
export const KEY_ORDERER_EMAIL = 'orderer_email';
export const KEY_OVERSEAS_FIRST_NAME = 'first_name';
export const KEY_OVERSEAS_LAST_NAME = 'last_name';
export const KEY_OVERSEAS_PHONE_NUMBER = 'receiver_phone_number';
export const KEY_OVERSEAS_PHONE_COUNTRY_CODE = 'receiver_phone_country_code';
export const KEY_OVERSEAS_EMAIL = 'email';
export const KEY_ADDRESS_ZIP_CODE = 'zip_code';
export const KEY_ADDRESS_COUNTRY = 'country_code';
export const KEY_ADDRESS_STATE = 'state';
export const KEY_ADDRESS_CITY = 'city';
export const KEY_ADDRESS_LINE_1 = 'address_line_1';
export const KEY_ADDRESS_LINE_2 = 'address_line_2';
export const KEY_OVERSEAS_ORDER_INFO = 'global_order_info';
// billing info
export const KEY_BILLING_INFO_FIRST_NAME = 'billing_info_first_name';
export const KEY_BILLING_INFO_LAST_NAME = 'billing_info_last_name';
export const KEY_BILLING_INFO_PHONE_NUMBER = 'billing_info_phone_number';
export const KEY_BILLING_INFO_PHONE_COUNTRY_CODE = 'billing_info_phone_country_code';
export const KEY_BILLING_INFO_EMAIL = 'billing_info_email';
export const KEY_BILLING_INFO_ZIP_CODE = 'billing_info_zip_code';
export const KEY_BILLING_INFO_COUNTRY = 'billing_info_country_code';
export const KEY_BILLING_INFO_STATE = 'billing_info_state';
export const KEY_BILLING_INFO_CITY = 'billing_info_city';
export const KEY_BILLING_INFO_LINE_1 = 'billing_info_address_line_1';
export const KEY_BILLING_INFO_LINE_2 = 'billing_info_address_line_2';
export const KEY_BILLING_INFO = 'billing_info';
export const VALUE_SELF_ENTER = '직접입력';
export const deliveryMemoChoices = [
    "부재 시 문 앞에 놓아주세요",
    "부재 시 경비실에 맡겨주세요",
    "배송 전 미리 연락주세요",
    "부재 시 전화나 문자로 연락 부탁드립니다",
];
export const COUNTRY_CODE_REGX = new RegExp('^[\\d+]+$');
const Checkout = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [error, setError] = useState(false);
    const router = useRouter();
    const locale = router.locale;
    const { ordNum } = router.query;
    const user = useContext(UserContext).user;
    const [isVerificationRequired, setIsVerificationRequired] = useState(true);
    const { t } = useTranslation('store-orders-ordNum-checkout');
    // const
    useEffect(async () => {
        if (!ordNum) {
            return;
        }
        try {
            const res = await Axios.post(`v1/store/orders/${ordNum}/cost`, {});
            if (res.status < 400) {
                const order = res.data;
                if (res.data?.is_verification_required === false) {
                    setIsVerificationRequired(false);
                }
                if (order) {
                    const optionIds = order.option_count_list.map(x => x.id);
                    const totalItems = order.option_count_list.reduce((totalCount, x) => totalCount + x.count, 0);
                    const discountedPrice = order.discounted_price;
                    const data = {
                        content_ids: optionIds,
                        num_items: totalItems,
                        value: discountedPrice,
                        currency: 'KRW'
                    };
                    trackPixel('InitiateCheckout', data);
                    const totalUniqueOptionCount = order.option_count_list.length;
                    const isLoginPurchase = Boolean(user);
                    typeof mixpanel !== 'undefined' && mixpanel.track('InitiateCheckout', {
                        order_number: ordNum,
                        is_login_purchase: isLoginPurchase,
                        totalCount: totalItems,
                        totalPrice: discountedPrice,
                        totalUniqueOptionCount
                    });
                    for (const optionCount of order.option_count_list) {
                        const loggingData = getLoggingDataFromOptionCount(optionCount, ordNum, isLoginPurchase);
                        typeof mixpanel !== 'undefined' && mixpanel.track('InitiateCheckoutOrderOption', loggingData);
                    }
                }
                setOrder(res.data);
            }
            else if (res.status === 404) {
                if (!user) {
                    redirectToLogin(router, false, undefined, undefined, true);
                }
                else {
                    setNotFound(true);
                }
                // setNotFound(true);
            }
            else if (res.status === 409) {
                alert(t('Checkout.orderAlreadyCompleted'));
                router.replace({ pathname: getOrderDetailRoute(ordNum) });
            }
            else {
                setError(true);
            }
        }
        catch (e) {
            setError(true);
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    }, [ordNum]);
    return (isLoading || user === undefined ?
        <Loading style={{ marginTop: 180, marginBottom: 330 }}/>
        :
            (error ?
                <ErrorPage title={t('Checkout.errorTitle')} content={t('Checkout.errorContent')} serverError={true}/>
                :
                    (notFound ?
                        <ErrorPage title={t('Checkout.notFoundTitle')} content={t('Checkout.notFoundContent')}/>
                        :
                            <>
                                <CheckoutInfo order={order} setOrder={setOrder} isVerificationRequired={isVerificationRequired}/>
                            </>)));
};
Checkout.getLayout = IamportLayout;
export default Checkout;

    async function __Next_Translate__getServerSideProps__195d0eac9f1__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/store/orders/[ordNum]/checkout',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__195d0eac9f1__ as getServerSideProps }
  