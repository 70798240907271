import inputStyles from "components/input/input.module.scss";
import styles from "components/input/CountryInputSelector.module.scss";
import utilStyles from 'styles/utils.module.scss'
import ArrowDownIcon from "images/icons/arrow_down_757575.svg";
import classNames from "classnames";
import React, {useRef, useState} from "react";
import {useOutsideAlerter} from "common/utils";
import {
    COUNTRY_CODE_TO_COUNTRY_INFO,
    COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES, countryChoices,
    countryCodeToChoice
} from "common/country_list";
import {CountryFlagImage} from "components/country/CountryImage";

export default function CountryInputSelector(props) {
    const {countryCode, placeholder, disableOnSingleChoice, onlyPossibleCountries, disable} = props;
    const onChange = props.onChange || function () {
    };

    const [isExpanded, setIsExpanded] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setIsExpanded(false);
    });
    const selectedCountryInfo = countryCodeToChoice[countryCode];
    const supportedCountryCodes = onlyPossibleCountries ? (COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES[countryCode])
        : (Object.values(COUNTRY_CODE_TO_COUNTRY_INFO).map(x => x.countryCode));

    if (!selectedCountryInfo) {
        return null;
    }

    return (
        <div ref={wrapperRef} className={styles.container}>
            <div
                className={classNames(
                    inputStyles.input,
                    !disable && inputStyles.dropdownInput,
                    isExpanded ? inputStyles.dropdownInputActive : undefined,
                    disable && inputStyles.input_disabled,
                )}
                onClick={() => !disable && setIsExpanded(val => !val)}
            >
                <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                    <CountryFlagImage size={24} countryCode={countryCode} circle/>
                    <span className={styles.currency}>{selectedCountryInfo.description}</span>
                </div>
                <ArrowDownIcon className={styles.arrowDownIcon} viewBox="0 0 20 20" height="16" width="16"/>
            </div>
            {
                //isExpanded &&
                <div className={classNames(inputStyles.selectorContainer, utilStyles.relative)}
                     style={{display: isExpanded ? 'block' : 'none'}}>
                    {
                        supportedCountryCodes && supportedCountryCodes?.map((countryCode, index) => {
                            const countryInfo = COUNTRY_CODE_TO_COUNTRY_INFO[countryCode];

                            if (!countryInfo) {
                                return null;
                            }

                            return (
                                <div
                                    key={`${countryCode}-${index}`}
                                    className={classNames(
                                        inputStyles.choice,
                                    )}
                                    onClick={(evt) => {
                                        onChange(countryInfo.countryCode);
                                        setIsExpanded(val => !val);
                                        evt.stopPropagation();
                                    }}
                                >
                                    <div className={classNames(utilStyles.flexRow, utilStyles.alignItemsCenter)}>
                                        <CountryFlagImage size={24} countryCode={countryInfo.countryCode} circle/>
                                        <span className={styles.currency}>{countryInfo.description}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}
