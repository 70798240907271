import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import classNames from "classnames";
import {
    KEY_ADDRESS_CITY,
    KEY_ADDRESS_COUNTRY,
    KEY_ADDRESS_ID,
    KEY_ADDRESS_LINE_1,
    KEY_ADDRESS_LINE_2,
    KEY_ADDRESS_STATE,
    KEY_ADDRESS_ZIP_CODE,
    KEY_OVERSEAS_EMAIL,
    KEY_OVERSEAS_FIRST_NAME,
    KEY_OVERSEAS_LAST_NAME,
    KEY_OVERSEAS_PHONE_COUNTRY_CODE,
    KEY_OVERSEAS_PHONE_NUMBER
} from "pages/store/orders/[ordNum]/checkout";
import {useContext, useRef, useState} from "react";
import UserContext from "context/AuthContext";
import CheckedCheckboxIcon from "images/icons/checkbox_checked.svg";
import utilStyles from "styles/utils.module.scss";
import UncheckedCheckboxIcon from "images/icons/checkbox_unchecked.svg";
import ArrowRightIcon from "images/icons/arrow_right.svg";
import CheckoutAddressCardGlobal from "components/cards/CheckoutAddressCardGlobal";


const CheckoutAddressInfoSectionGlobal = (props) => {
    const {isLoading, formik} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;
    const userContext = useContext(UserContext);
    const user = userContext.user;

    const [isAddressListModalOpen, setIsAddressListModalOpen] = useState(false);

    const onChangeClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('GlobalCheckoutAddressChangeButtonClick');
        if (isLoading) return;
        setIsAddressListModalOpen(val => !val);
    };

    const nameInputRef = useRef(null);

    return (
        <div className={styles.section}>
            {
                (!!user && values[KEY_ADDRESS_ID]) &&
                <div
                    className={classNames(styles.title, utilStyles.cursorPointer, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentSpaceBetween)}>
                    <span>Shipping Information</span>
                    {

                        <div className={utilStyles.cursorPointer} onClick={onChangeClick}>
                            <span
                                className={isLoading ? checkoutStyles.rightActionDisabled : checkoutStyles.rightAction}>Change</span>
                            <ArrowRightIcon viewBox="0 0 16 16"
                                            className={isLoading ? checkoutStyles.rightActionArrowDisabled : checkoutStyles.rightActionArrow}/>
                        </div>

                    }
                </div>
            }
            <CheckoutAddressCardGlobal
                isAddressListModalOpen={isAddressListModalOpen}
                setIsAddressListModalOpen={setIsAddressListModalOpen}
                errors={errors}
                touched={touched}
                nameInputRef={nameInputRef}
                isLoading={isLoading}
                user={userContext.user}
                onChangeAddress={(address) => {
                    setValues(oldValues => {
                        const newValues = JSON.parse(JSON.stringify(oldValues));
                        newValues[KEY_ADDRESS_COUNTRY] = address.country_code;
                        newValues[KEY_ADDRESS_CITY] = address.city;
                        newValues[KEY_ADDRESS_STATE] = address.state;
                        newValues[KEY_ADDRESS_ZIP_CODE] = address.postal_code;
                        newValues[KEY_ADDRESS_LINE_1] = address.rough_address;
                        newValues[KEY_ADDRESS_LINE_2] = address.detail_address;
                        newValues[KEY_OVERSEAS_PHONE_COUNTRY_CODE] = address.phone_country_code;
                        newValues[KEY_OVERSEAS_LAST_NAME] = address.receiver_name.split(', ')[0];
                        newValues[KEY_OVERSEAS_FIRST_NAME] = address.receiver_name.split(', ')[1];
                        newValues[KEY_OVERSEAS_PHONE_NUMBER] = address.phone_number;
                        newValues[KEY_OVERSEAS_EMAIL] = address.email;
                        newValues[KEY_ADDRESS_ID] = address.id;
                        return newValues;
                    });
                }}
                setValues={setValues}
                values={JSON.parse(JSON.stringify(values))}
                setFieldValue={setFieldValue}
            />
            <div
                className={classNames(styles.title, utilStyles.cursorPointer, utilStyles.flexRow, utilStyles.alignItemsCenter, utilStyles.justifyContentSpaceBetween)}>
                {
                    !values[KEY_ADDRESS_ID] &&
                    <div onClick={() => {
                        !(isLoading) && setFieldValue('is_default', !values.is_default)
                    }}>
                        {
                            values['is_default']  ?
                                <CheckedCheckboxIcon viewBox="0 0 24 24" width="20" height="20"
                                                     className={utilStyles.verticalMiddle}/>
                                :
                                <UncheckedCheckboxIcon viewBox="0 0 24 24" width="20" height="20"
                                                       className={utilStyles.verticalMiddle}/>
                        }
                        <span className={styles.sameAsOrderer}>Set as my default address</span>
                    </div>
                }
            </div>
        </div>
    )
};

export default CheckoutAddressInfoSectionGlobal;
