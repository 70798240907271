import {createContext, useEffect, useMemo, useRef, useState} from "react";
import {Axios} from "api";
import {useRouter} from "next/router";
import {captureException} from "@sentry/nextjs";
import {fetchUserInfo, getLocalStorageUserInfo, invalidateUserInfo, saveUserInfo} from "api/user";
import {postRNMessage} from "common/utils";
import {REVERSE_MESSAGE_TYPE_USER_INFO_FETCHED} from "common/rn_message";
import {redirectToLogin} from "common/redirect";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import Loading from "components/Loading";
import {
    AUTH_TYPE_EMAIL,
    marketingAgreementRoute,
    privacyPolicyRoute,
    snsSignUpRoute,
    termsOfServiceRoute
} from "common/const";
import {toastContextRef} from "context/ToastContext";
import useCouponDownloadByUrl from "hooks/useCouponDownloadByUrl";
import useTranslation from "next-translate/useTranslation";


const UserContext = createContext(undefined);
export default UserContext;

export const UserContextProvider = ({isPageProtected, children}) => {
    const [user, setUser] = useState(undefined);
    const {t} = useTranslation('auth');
    const [couponInfo, setCouponInfo] = useState({
        'count': 0,
        'coupons': [],
        'expired_coupons': [],
        'downloadable_coupons': [],
    });
    const userRef = useRef(undefined);

    const fetchCouponInfo = async () => {
        try {
            const res = await Axios.get('v1/coupons');
            if (res.status < 400) {
                setCouponInfo(res.data);
            }
        } catch (e) {
            captureException(e);
        }
    };

    useEffect(() => {
        fetchCouponInfo();
    }, [user?.nickname]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return
        }
        window.debug = Boolean(user?.is_beta_tester)
    }, [user?.is_beta_tester])


    const getUserInfo = async () => {
        const userInfo = await fetchUserInfo(t('user.error'));
        if (userInfo !== undefined) {
            userRef.current = userInfo;
            return userInfo;
        } else {
            userRef.current = null;
        }
        // user is undefined, probably due to server error
        const localUserInfo = getLocalStorageUserInfo();
        if (localUserInfo) {
            return localUserInfo;
        }
        return null;
    };

    const router = useRouter();

    const commissionRate = useMemo(() => {
        return Boolean(user?.commission_rate) ? user?.commission_rate : 0;
    }, [user?.commission_rate])

    const fetchUser = async () => {
        try {
            const userInfo = await fetchUserInfo(t('user.error'));
            if (userInfo !== undefined) {
                userRef.current = userInfo;
                setUser(userInfo);
            } else {
                userRef.current = null;
            }
        } catch (e) {
            captureException(e);
        }
    };

    const forceUserNicknameSettings = async (userInfo) => {
        if (userInfo.auth_type !== AUTH_TYPE_EMAIL) {
            if (router.asPath !== snsSignUpRoute && !router.asPath.startsWith(termsOfServiceRoute) && !router.asPath.startsWith(privacyPolicyRoute) && !router.asPath.startsWith(marketingAgreementRoute)) {
                await router.replace(snsSignUpRoute);
            }
        }
    };

    useEffect(async () => {
        const userInfo = await getUserInfo();
        await setUser(userInfo);
        postRNMessage({
            type: REVERSE_MESSAGE_TYPE_USER_INFO_FETCHED,
            is_logined: userInfo ? userInfo.nickname : false,
            error: false,
        });
    }, []);

    useEffect(async () => {
        let trial = 0;
        while (user === undefined && trial < 5) {
            trial++;
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        if (isPageProtected && !user) {
            redirectToLogin(router, false);
        }
    }, [isPageProtected, user]);

    useEffect(async () => {
        if (Boolean(user)) {
            mixpanel.identify(user.email);
            mixpanel.people.set({
                "name": user.nickname,
                "email": user.email,
                "nickname": user.nickname,
                "auth_type": user.auth_type,
                "is_creator": user.is_creator,
                "is_seller": user.is_seller,
                "is_verified": user.is_verified,
            });
        }
        if (user && !user.nickname) {
            await forceUserNicknameSettings(user);
        }
    }, [user]);

    useEffect(() => {
        const migratePouch = async () => {
            if (!!user && !!user.nickname) {
                try {
                    const nonMemberPouchItems = (
                        JSON.parse(window.localStorage.getItem('pouch_items')) || []
                    ).sort((a, b) => a.created_at - b.created_at).map(e => {
                        return {
                            option_id: e.option_id,
                            count: e.count,
                            referral_id: e.referral_id,
                            referral_post_id: e.referral_post_id,
                        }
                    });
                    if (nonMemberPouchItems.length > 0) {
                        const res = await Axios.post('v1/store/pouch/', {
                            options: nonMemberPouchItems
                        });
                        if (res.status < 400) {
                            window.localStorage.removeItem('pouch_items');
                        }
                    }
                } catch (e) {
                    captureException(e);
                }
            }
        }
        migratePouch();
    }, [user?.nickname]);

    const logout = async () => {
        try {
            const res = await Axios.post('v1/auth/sign-out/', {});
            if (res.status >= 400) {
                toastContextRef.current?.info(t('sign-out.error'));
            }
        } catch (e) {
            toastContextRef.current?.info(t('sign-out.error'));
            captureException(e);
        } finally {
            invalidateUserInfo();
            setUser(null);
            window.scrollTo(0, 0);
            router.reload();
        }
    }

    useCouponDownloadByUrl(user);

    const addOrUpdateAddress = (address, defaultId) => {
        if (user) {
            setUser(oldUser => {
                const newUser = Object.assign({}, oldUser);
                newUser.default_address_id = defaultId;
                if (newUser.addresses.filter(e => e.id === address.id).length > 0) {
                    newUser.addresses = newUser.addresses.map(e => e.id === address.id ? address : e);
                } else {
                    newUser.addresses.push(address);
                }
                newUser.addresses.sort((a, b) => {
                    if (defaultId === a.id) {
                        return -1;
                    } else if (defaultId === b.id) {
                        return 1;
                    }
                    return b.created_at - a.created_at;
                });
                return newUser;
            })
        }
    }

    const deleteAddress = (addressId) => {
        if (user) {
            setUser(oldUser => {
                const newUser = Object.assign({}, oldUser);
                newUser.addresses = newUser.addresses.filter(e => e.id !== addressId);
                return newUser;
            })
        }
    }

    const setPoint = (point) => {
        if (user) {
            setUser(oldUser => {
                const newUser = Object.assign({}, oldUser);
                newUser.point = point;
                return newUser;
            })
        }
    }

    const setCouponCount = (count, downloadableCount) => {
        if (user) {
            setUser(oldUser => {
                const newUser = Object.assign({}, oldUser);
                newUser.coupon_count = count;
                newUser.downloadable_coupon_count = downloadableCount;
                return newUser;
            })
        }
    }

    const contextValue = useMemo(() => ({
        user,
        setUser,
        addOrUpdateAddress,
        setPoint,
        deleteAddress,
        logout,
        setCouponCount,
        fetchUser,
        couponInfo,
        fetchCouponInfo,
        userRef,
        commissionRate
    }), [user, logout, setUser, setPoint, deleteAddress, addOrUpdateAddress, setCouponCount, fetchUser, couponInfo, fetchCouponInfo, userRef, commissionRate]);

    return (
        // the Provider gives access to the context to its children
        <UserContext.Provider value={contextValue}>
            {
                isPageProtected && !Boolean(user) ?
                    <div className={classNames(utilStyles.justifyContentCenter, utilStyles.alignItemsCenter)}
                         style={{height: '100vh'}}>
                        <Loading/>
                    </div> :
                    children
            }
        </UserContext.Provider>
    );
};
