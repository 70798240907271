import {useEffect} from "react";
import {COUNTRY_CODE, KOREA_BASE_URL} from "common/const";
import {useRouter} from "next/router";

const useCountryCodeRedirect = () => {
    const router = useRouter();
    const {locale, query, replace} = router;

    useEffect(() => {
        if (typeof window === 'undefined')  {
            return;
        }

        const serverCountryCode = COUNTRY_CODE.toUpperCase();
        const localeCountryCode = locale.toUpperCase();

        console.log('serverCountryCode', serverCountryCode, localeCountryCode, locale);

        if (serverCountryCode !== 'KR' && localeCountryCode !== 'EN' && localeCountryCode !== 'KO' && localeCountryCode !== 'KR') {
            return;
        }

        const paths = window.location.pathname ? window.location.pathname.split('/') : [];
        const searchParams = new URLSearchParams(window.location.search);
        const queryObject = Object.fromEntries(searchParams.entries());
        const urlCountryCode = paths.length > 1 ? paths[1] : '';
        let finalPathname = '';

        if (serverCountryCode !== 'KR' && (localeCountryCode === 'KR' || localeCountryCode === 'KO')) {
            const remainingPath = paths.slice(2).join("/");
            let redirectUrl = `https://${KOREA_BASE_URL}/${remainingPath}`;
            if (queryObject && Object.keys(queryObject).length > 0) {
                const queryString = new URLSearchParams(queryObject).toString();
                redirectUrl += `?${queryString}`;
            }
            window.location.href = redirectUrl;
            return;
        }


        if (serverCountryCode !== 'KR' && localeCountryCode === 'EN') {
            if (paths.length <= 1) {
                replace(
                    {
                        pathname: `/us`,
                        query: {
                            ...queryObject,
                        },
                    },
                    undefined,
                    {shallow: true}
                )
                return;
            }

            if (urlCountryCode === 'en') {
                finalPathname = paths.map((path, index) => (index === 1 ? "us" : path)).join("/");
            } else {
                finalPathname = '/us' + window.location.pathname;
            }

            console.log('finalPathname', finalPathname, queryObject);

            replace(
                {
                    pathname: finalPathname,
                    query: {
                        ...queryObject,
                    },
                },
                undefined,
                {shallow: true}
            )
        }

        if (serverCountryCode === "KR") {
            // kr, ko 이건 us, jp 뭐든 상관없이 다 없앤다
            if (urlCountryCode.length === 2) {
                replace(
                    {
                        pathname: `/${paths.slice(2).join("/")}` || '/',
                        query: { ...queryObject },
                    },
                    undefined,
                    { shallow: urlCountryCode === 'ko' }
                )
            }
        }
    }, [locale]);
};

export default useCountryCodeRedirect;
