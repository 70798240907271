import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import {formattedPrice, numberWithComma} from "common/utils";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import {KEY_USED_POINTS} from "pages/store/orders/[ordNum]/checkout";
import {useContext, useMemo} from "react";
import UserContext from "context/AuthContext";
import useTranslation from "next-translate/useTranslation";
import {useRouter} from "next/router";


const CheckoutPriceInfoSection = (props) => {
  const {formik, order, finalPrice} = props;
  const {t} = useTranslation("component-orders-checkout-price-section")
  const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;
  const userContext = useContext(UserContext);
  const user = userContext.user;
  const router = useRouter();
  const locale = router.locale;

  const currency = useMemo(() => {
    return order?.currency;
  }, [order?.currency])

  const taxPrice = useMemo(() => {
    if (!Boolean(order?.tax_price)) {
      return 0;
    }
    return order?.tax_price;
  }, [order?.tax_price])

  return (

      <div className={styles.section}>
        <span className={styles.title}>{t('title')}</span>
        <div className={styles.priceContainer}>
          <div className={styles.priceRow}>
            <span className={styles.priceKey}>{t('subTitle.totalProductAmount')}</span>
            <div>
              <span className={styles.priceValue}>{formattedPrice(order.base_price, currency)}</span>
              <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceKey}>{t('subTitle.discount')}</span>
            <div>
              <span className={styles.priceValue}>-{formattedPrice(order.base_price - order.discounted_price, currency)}</span>
              <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          {
              taxPrice > 0 &&
              <div className={styles.priceRow}>
                <span className={styles.priceKey}>{t('subTitle.tax')}</span>
                <div>
                  <span className={styles.priceValue}>+{formattedPrice(taxPrice, currency)}</span>
                  <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
                </div>
              </div>
          }
          <div className={styles.priceRow}>
            <span className={styles.priceKey}>{t('subTitle.deliveryFee')}</span>
            <div>
              <span className={styles.priceValue}>+{formattedPrice(order.delivery_fee, currency)}</span>
              <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceKey}>{t('subTitle.couponUsage')}</span>
            <div>
              <span className={styles.priceValue}>-{formattedPrice(order.coupon_discount, currency)}</span>
              <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          <div className={styles.priceRow}>
            <span className={styles.priceKey}>{t('subTitle.pointUsage')}</span>
            <div>
              <span className={styles.priceValue}>-{formattedPrice(values[KEY_USED_POINTS] || 0, currency)}</span>
              <span className={classNames(styles.priceValue, styles.priceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          <div className={styles.totalPriceMargin} />
          <div className={styles.border} />
          <div className={styles.totalPriceRow}>
            <span className={styles.totalPriceKey}>{t('subTitle.totalPaymentAmount')}</span>
            <div>
              <span className={styles.totalPriceValue}>{formattedPrice(finalPrice, currency)}</span>
              <span className={classNames(styles.totalPriceValue, styles.totalPriceWon)}>{t(`common:currency.${currency}`)}</span>
            </div>
          </div>
          {
              router.locale === 'ko' &&
              <div className={classNames(utilStyles.flexRow, utilStyles.justifyContentEnd, styles.mileageText)}>
                {!user && t('point.signUp')} {t('point.upTo')}&nbsp;
                <span className={styles.mileage}>
              {formattedPrice(Math.ceil(finalPrice * order.acc_point_rate / 100.0), currency)}p
              </span>
                &nbsp;{t('point.earnedPoint')}
              </div>
          }
        </div>
      </div>
  )
};

export default CheckoutPriceInfoSection;
