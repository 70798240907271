import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Axios} from "api";
import {getLocalStorageCountryCode} from "common/utils";
import {KOREA_COUNTRY_CODE} from "common/i18n";
import {captureException} from "@sentry/nextjs";
import {KEY_ADDRESS_COUNTRY} from "pages/store/orders/[ordNum]/checkout";
import Cookies from "js-cookie";
import {COUNTRY_CODE} from "common/const";


const DeliveryContext = createContext(undefined);

export let deliveryContextRef = {};
export default DeliveryContext;

const DeliveryContextWrapper = ({children}) => {
    const context = useContext(DeliveryContext);

    useEffect(() => {
        deliveryContextRef.current = context;
    }, [])

    useEffect(() => {
        deliveryContextRef.current = context;
    }, [context])

    return (
        <>
            {children}
        </>
    )
}

export const DeliveryContextProvider = ({children}) => {
    const [deliveryStrategy, setDeliveryStrategy] = useState(null);

    const fetchDeliveryStrategy = async (countryCode) => {
        if (countryCode === KOREA_COUNTRY_CODE) {
            return;
        } else if (COUNTRY_CODE === KOREA_COUNTRY_CODE) {
            return ;
        }

        try {
            const res = await Axios.get('v1/delivery/strategy', {
                params: {
                    [KEY_ADDRESS_COUNTRY]: countryCode,
                }
            });

            if (res.status < 400) {
                setDeliveryStrategy(res.data);
                return res.data;
            } else {
                captureException(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        }
    }

    const fetchInstructions = async (countryCode) => {
        if (countryCode === KOREA_COUNTRY_CODE) {
            return;
        }

        try {
            const res = await Axios.get('v1/delivery/instructions', {
                params: {
                    [KEY_ADDRESS_COUNTRY]: countryCode,
                }
            });

            if (res.status < 400) {
                return res.data;
            } else {
                captureException(JSON.stringify(res.data));
            }
        } catch (e) {
            captureException(e);
        }
    }

    useEffect(async () => {
        const nextCountry = Cookies.get('NEXT_COUNTRY');
        if (nextCountry) {
            const deliveryStrategy = await fetchDeliveryStrategy(nextCountry.toUpperCase());
            if (deliveryStrategy) {
                setDeliveryStrategy(deliveryStrategy);
            }
        }
    }, []);

    const priceSortedDeliveryFee = useMemo(() => {
        if (!deliveryStrategy) {
            return [];
        }

        return Object.entries(
            deliveryStrategy?.delivery_table
        ).sort(
            (a, b) => a[0] - b[0]
        ).map(
            ([price, fee]) => [parseInt(price), fee]
        ) || [];
    }, [deliveryStrategy]);

    const getDeliveryFee = (price) => {
        const countryCode = getLocalStorageCountryCode() ?? 'KR';
        if (!deliveryStrategy || countryCode === 'KR') {
            return 0;
        }

        for (const [priceRange, fee] of priceSortedDeliveryFee) {
            if (price <= priceRange) {
                return fee;
            }
        }
        return 0;
    }

    const contextValue = {
        deliveryStrategy, priceSortedDeliveryFee, getDeliveryFee, fetchDeliveryStrategy, fetchInstructions
    };

    return (
        <DeliveryContext.Provider value={contextValue}>
            <DeliveryContextWrapper>
                {children}
            </DeliveryContextWrapper>
        </DeliveryContext.Provider>
    )
}
