import Modal from "components/Modal";
import styles from "components/modal/OptionSelectorPouchAddedModal.module.scss";
import FullButton from "components/buttons/FullButton";
import {useRouter} from "next/router";
import Image from "next/image";
import HorizontalProducts from "components/layout/store/products/HorizontalProducts";
import ArrowRightIcon from "images/icons/arrow_right_black_16.svg";
import {pouchRoute, storeRoute} from "common/const";
import Link from "next/link";
import useTranslation from "next-translate/useTranslation";

const OptionSelectorPouchAddedModal = (props) => {
    const {isOpened, handleClose, product, recommendedProducts, noBackgroundBlur} = props;
    const {t} = useTranslation('components-modal-OptionSelectorPouchAddedModal');
    const router = useRouter();

    const handleRecommendedProductClick = (product, itemIdx) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('OptionSelectorPouchAddedModalRecommendedProductClick', {
            productName: product.name,
            productId: product.id,
            itemIdx,
        });
        handleClose();
    };

    const handleGoToPouchButtonClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('OptionSelectorPouchAddedModalGoToPouchButtonClick');
        handleClose();
        router.push(pouchRoute);
    };

    return (
        <Modal bottom={true} isOpen={isOpened} width={'100%'} close={handleClose} noBackgroundBlur={noBackgroundBlur}>
            <div className={styles.modalContainer}>
                <div className={styles.productInfoWrapper}>
                    <div className={styles.imageWrapper}>
                        <Image unoptimized src={product.thumbnail_url}
                               width={40} height={40}
                               layout="fill" objectFit="cover"
                        />
                    </div>
                    <span className={styles.pouchDoneText}>
                        {t('OptionSelectorPouchAddedModal.addedToCart')}
                    </span>
                    <div className={styles.goToButtonWrapper}>
                        <FullButton
                            title={t('OptionSelectorPouchAddedModal.goToCart')}
                            height={30}
                            fontSize={14}
                            onClick={handleGoToPouchButtonClick}
                        />
                    </div>
                </div>
                <div className={styles.border} />
                <div className={styles.productListContainer}>
                    <Link href={storeRoute}>
                        <a className={styles.recommendedProductTitle}>
                            <span>{t('OptionSelectorPouchAddedModal.otherCustomersViewed')}</span>
                            <ArrowRightIcon viewBox="0 0 16 16" className={styles.arrowRightIcon}/>
                        </a>
                    </Link>
                    <HorizontalProducts
                        products={recommendedProducts}
                        onProductClick={handleRecommendedProductClick}
                    />
                </div>
            </div>
        </Modal>
    )
};

export default OptionSelectorPouchAddedModal;
