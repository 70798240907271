import styles from "components/buttons/fullButton.module.scss";
import classNames from "classnames";


export default function FullButton (props) {
    const {
        title, height, fontSize, light, lightInactive, lightWhite, disabled, onClick, nonBold, white, children,
        black, noCursor, useDiv, fontWeight, sidePadding, showRightArrow, borderRadius, buttonType, totalBlack,
        totalBlackInactive, fontColor, backgroundColor, red, theme, width
    } = props;

    if (useDiv) {
        return (
            <div
                onClick={!disabled ? (onClick || function () {
                }) : function () {
                }}
                className={classNames(
                    styles.buttonContainer, white ? styles.white : undefined,
                    light ? styles.light : (disabled ? styles.disabled : undefined),
                    black ? styles.black : undefined,
                    totalBlack ? styles.totalBlack : undefined,
                    totalBlackInactive && styles.totalBlackInactive,
                )}
                style={
                    {
                        color: fontColor,
                        height: height,
                        width: width,
                        fontSize: fontSize,
                        fontWeight: nonBold ? 500 : fontWeight,
                        cursor: noCursor ? 'default' : undefined,
                        borderRadius,
                        backgroundColor: backgroundColor
                    }}>
                {title || children}
            </div>
        )
    } else {
        return (
            <button
                onClick={!disabled ? (onClick || function () {}) : function () {}}
                className={
                    classNames(
                        theme ? styles[`theme-${theme}`] : styles[`theme-default`],
                        styles.buttonContainer, white ? styles.white : undefined,
                        light ? styles.light : (disabled ? styles.disabled : undefined), black ? styles.black : undefined,
                        totalBlack ? styles.totalBlack : undefined,
                        lightWhite ? styles.lightWhite : undefined,
                        lightInactive && styles.lightInactive,
                        red && styles.red,
                        totalBlackInactive && styles.totalBlackInactive,
                    )
                }
                type={buttonType || 'button'}
                style={
                    {
                        color: fontColor,
                        height: height,
                        width: width,
                        fontSize: fontSize,
                        fontWeight: nonBold ? 500 : fontWeight,
                        cursor: noCursor ? 'default' : undefined,
                        paddingLeft: `${sidePadding}px`,
                        paddingRight: `${sidePadding}px`,
                        borderRadius,
                        backgroundColor: backgroundColor,
                    }
                }>
                {title || children}
            </button>
        )
    }
};
