import {createContext, useContext, useEffect, useState} from "react";
import Cookies from "js-cookie";
import {captureException} from "@sentry/nextjs";
import {Axios} from "api";
import {useRouter} from "next/router";

const ReferralContext = createContext(undefined);

export let referralContextRef = {};
export default ReferralContext;


const ReferralContextWrapper = ({children}) => {
    const context = useContext(ReferralContext);

    useEffect(() => {
        referralContextRef.current = context;
    }, [])

    useEffect(() => {
        referralContextRef.current = context;
    }, [context])

    return (
        <>
            {children}
        </>
    )
}

export const ReferralContextProvider = ({children}) => {
    const [referralCode, setReferralCode] = useState(null);
    const router = useRouter();

    const hasReferralCode = () => {
        return Boolean(referralCode);
    }

    const fetchValidReferralCode = async (code) => {
        try {
            const res = await Axios.get('v1/referral/validate', {
                params: {
                    code: code,
                }
            });

            if (res.status < 400) {
                return res?.data?.is_valid;
            }
        } catch (e) {
            captureException(e);
        }
        return false;
    };

    const setGlobalReferralCode = (code) => {
        console.log('fffff', code)
        if (!code || code === referralCode) {
            return;
        }

        fetchValidReferralCode(code).then((isValid) => {
            if (isValid) {
                setReferralCode(code);
                const siteUrl = `.${window.location.host.split('.').slice(-2).join('.')}`;
                Cookies.set('global_ref_code', code, {expires: 14, domain: siteUrl});
            }
        });
    };

    useEffect(() => {
        const globalReferralCode = Cookies.get('global_ref_code');

        if (globalReferralCode) {
            const isValid = fetchValidReferralCode(globalReferralCode);
            if (isValid) {
                setReferralCode(globalReferralCode);
            }
        }
    },[]);

    useEffect(() => {
        const splitPath = router?.asPath?.split('/');

        if (!splitPath) {
            return;
        }
        if (splitPath.length >= 3) {
            const userCode = splitPath[2].split('?')[0]

            if (splitPath[1] === 'c' && userCode) {
                setGlobalReferralCode(userCode);
            }
        }

    }, [router?.asPath])

    const contextValue = {
        hasReferralCode, setGlobalReferralCode, fetchValidReferralCode, referralCode, setReferralCode
    };

    return (
        <ReferralContext.Provider value={contextValue}>
            <ReferralContextWrapper>
                {children}
            </ReferralContextWrapper>
        </ReferralContext.Provider>
    )
}