
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NavigationLayout from "components/layout/NavigationLayout";
import styles from "pages/mypage/orders.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import OrderCountBriefCard from "components/cards/order/OrderCountBriefCard";
import { useContext, useEffect, useState } from "react";
import OrderCard from "components/cards/order/OrderCard";
import OrderStatusInfo from "components/OrderStatusInfo";
import { Axios } from "api";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "components/Loading";
import OrderOptionListCard from "components/cards/order/OrderOptionListCard";
import classNames from "classnames";
import { captureException, captureMessage } from "@sentry/nextjs";
import UserContext from "context/AuthContext";
import OrderGiftCard from "components/cards/order/OrderGiftCard";
import OrderReservationCard from "components/cards/order/OrderReservationCard";
import useTranslation from "next-translate/useTranslation";
const ROUTE_ONE_MONTH = 1;
const ROUTE_SIX_MONTHS = 6;
export const STATUS_PAYMENT_WAIT = 'PAYMENT_WAIT';
export const STATUS_PAYMENT_DONE = 'PAYMENT_DONE';
export const STATUS_PREPARING = 'PREPARING';
export const STATUS_DELIVERING = 'DELIVERING';
export const STATUS_DELIVERY_DONE = 'DELIVERY_DONE';
export const STATUS_CONFIRM_PURCHASE = 'CONFIRM_PURCHASE';
export const STATUS_CLAIMED = 'CLAIMED';
const PAGE_SIZE = 10;
const Orders = () => {
    const { t } = useTranslation('mypage-orders');
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cursor, setCursor] = useState(null);
    const { fetchUser } = useContext(UserContext);
    const [orderHistoryCountDict, setOrderHistoryCountDict] = useState({});
    const router = useRouter();
    const listName = router.query.status === STATUS_CLAIMED ? t('listNames.cancelExchangeReturn') : t('listNames.order');
    useEffect(async () => {
        fetchCount();
        fetchUser();
    }, []);
    useEffect(async () => {
        if (!router.isReady) {
            return;
        }
        await fetchMoreOrders(null);
    }, [router.query]);
    const fetchCount = async () => {
        try {
            setIsLoading(true);
            const res = await Axios.get('v1/store/order-history-count', {
                params: { months: 1200 }
            });
            if (res.status < 400) {
                setOrderHistoryCountDict(res.data);
            }
            else {
                setError(res.data.display_message || t('Orders.fetchError', { listName }));
            }
        }
        catch (e) {
            setError(t('Orders.fetchError', { listName }));
        }
        finally {
            setIsLoading(false);
        }
    };
    const fetchMoreOrders = async (cursorOverride) => {
        const status = router.query.status;
        setError(null);
        try {
            setIsLoading(true);
            const res = await Axios.get('v1/store/order-history/', {
                params: {
                    months: 1200, status: status, size: PAGE_SIZE, cursor: cursorOverride
                }
            });
            if (res.status < 400) {
                if (status !== router.query.status) {
                    return;
                }
                setCursor(res.data.cursor);
                setOrders(oldArray => {
                    if (cursorOverride == null) {
                        return res.data.order_history_list;
                    }
                    return [...oldArray, ...res.data.order_history_list];
                });
            }
            else {
                alert(res.data.display_message || t('Orders.fetchError', { listName }));
                captureMessage(JSON.stringify(res.data));
            }
        }
        catch (e) {
            captureException(e);
            alert(t('Orders.fetchError', { listName }));
        }
        finally {
            setIsLoading(false);
        }
    };
    return (<>
            <div className={classNames(styles.container, utilStyles.pageContainer)}>
                <div className={classNames(utilStyles.newSidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar title={t('Orders.title', { listName })} isBack close={() => router.back()}/>
                </div>
                <OrderCountBriefCard orderHistoryCountDict={orderHistoryCountDict}/>
                <div className={utilStyles.mobileBorder}/>
                <InfiniteScroll scrollThreshold={0.8} hasMore={cursor != null} dataLength={orders.length} next={() => fetchMoreOrders(cursor)} endMessage={undefined} loader={<div></div>} style={{ overflow: "visible" }}>
                {orders.map(order => {
            return (<OrderCard key={order.order_number} orderNumber={order.order_number} orderedAt={order.ordered_at} showLinkToDetail>
                                {order.reservations.length > 0 ?
                    order.reservations.map(reservation => {
                        return (<OrderReservationCard key={reservation.order_option_id} orderNumber={order.order_number} reservation={reservation} onReviewPostSuccess={() => {
                                setOrders([]);
                                fetchMoreOrders(null);
                            }} currency={order?.currency}/>);
                    }) :
                    order.gifts.length > 0 ?
                        order.gifts.map(gift => {
                            return (<OrderGiftCard key={gift.gift_id} orderNumber={order.order_number} gift={gift}/>);
                        }) :
                        order.order_exchanges.concat(order.order_refunds).concat(order.order_options).sort((a, b) => {
                            const orderOptionIdsDiff = (b.order_option_id || b.source_order_option_id) - (a.order_option_id || a.source_order_option_id);
                            if (orderOptionIdsDiff !== 0) {
                                return orderOptionIdsDiff;
                            }
                            else {
                                return (b.ordered_at || b.requested_at) - (a.ordered_at || a.requested_at);
                            }
                        }).map(el => {
                            const key = `${order.order_number}-e-${el.order_exchange_id || ''}-r-${el.order_refund_id || ''}-o-${el.order_option_id || ''}`;
                            return <OrderOptionListCard key={key} orderNumber={order.order_number} currency={order?.currency} orderOption={el} onReviewPostSuccess={() => {
                                    setOrders([]);
                                    fetchMoreOrders(null);
                                }} onPurchaseConfirmSuccess={() => {
                                    setCursor(null);
                                    setOrders([]);
                                    fetchMoreOrders(null);
                                }}/>;
                        })}
                            </OrderCard>);
        })}
                </InfiniteScroll>
                {!isLoading && orders.length === 0 &&
            <div className={styles.error}>
                        {error ? error : t('Orders.noOrders', { listName })}
                    </div>}
                {isLoading &&
            <Loading style={{ marginTop: isMobile ? 40 : 60 }}/>}
                <div style={{ height: 60 }}/>
                <div className={styles.orderStatusWrapper}>
                    <OrderStatusInfo />
                </div>

            </div>
        </>);
};
Orders.getLayout = NavigationLayout;
async function getStaticProps(context) {
    return {
        props: {
            protected: true
        }
    };
}
export default Orders;

    async function __Next_Translate__getStaticProps__195d0eaa8c7__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/mypage/orders',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d0eaa8c7__ as getStaticProps }
  