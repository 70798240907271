import {isApp} from "common/utils";
import {KOREA_COUNTRY_CODE} from "common/i18n";
import {COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES} from "common/country_list";

export const VERSION = process.env.VERSION;

// ENDPOINTS

let clientUrl = typeof window !== 'undefined' ? window.location.host : '';
const useHemekoLab = clientUrl.includes("hemekolab.com") && (process.env.COUNTRY_CODE === 'KR');
export const BASE_URL = (
    (useHemekoLab ? process.env.BASE_URL.replace('hemeko.com', 'hemekolab.com') : process.env.BASE_URL) ||
    "https://local.hemeko.com:8000"
);
export const SSR_BASE_URL = process.env.SSR_BASE_URL || "https://host.docker.internal:8000";

export const US_BASE_URL = (
    (useHemekoLab ? process.env.US_BASE_URL.replace('hemeko.com', 'hemekolab.com') : process.env.US_BASE_URL) ||
    "https://local.hemeko.com:8000"
);
export const US_SSR_BASE_URL = process.env.US_SSR_BASE_URL || "https://host.docker.internal:8000";



export const SITE_URL = (
    typeof window !== 'undefined' ?
        `${window.location.protocol}//${window.location.host}`
        :
        (process.env.SITE_URL)
);

export const CDN_BASE_URL = process.env.CDN_URL || "https://cf.hemekolab.com";
export const US_CDN_BASE_URL = process.env.US_CDN_URL || "https://cf.hemekolab.com";

export const KOREA_BASE_URL = process.env.KR_DOMAIN || "https://www.hemeko.com";

export const SERVICE_ENV = process.env.ENV;

// AUTH
export const NAVER_CLIENT_ID = "7vSzpEUAJJeo6BcSzeBw";
export const KAKAO_CLIENT_ID = "fe416da5007738a311d2633d3892618d";
export const FB_CLIENT_ID = "1901379603583153";
export const GOOGLE_CLIENT_ID = "629758199011-n6idgtp9lusq8c1vj4qm6slsfofpkl2a.apps.googleusercontent.com";
export const APPLE_CLIENT_ID = 'com.hemekolab.service';

export const AUTH_TYPE_NAVER = "naver";
export const AUTH_TYPE_KAKAO = "kakao";
export const AUTH_TYPE_FACEBOOK = "facebook";
export const AUTH_TYPE_EMAIL = 'email';
export const AUTH_TYPE_GOOGLE = "google";
export const AUTH_TYPE_APPLE = "apple";


export const IAMPORT_STORE_ID = process.env.IAMPORT_STORE_ID;
export const PORT_ONE_KEY = process.env.PORT_ONE_KEY;
export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;
export const GTM_ID = process.env.GTM_ID;
export const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN;
export const COUNTRY_CODE = process.env.COUNTRY_CODE;
export const LOCALE = process.env.LOCALE;

// ROUTES
/* STORE */
export const homeRoute = '/';
export const getHomeRoute = () => {
    if (!isApp()) {
        return '/';
    } else {
        return '/home';
    }
}
export const isHomeRoute = (route) => {
    return route === '/' || route === '/home';
}


export const reservationProductsRoute = '/reservation/products';
export const getReservationProductRoute = (pid) => `${reservationProductsRoute}/${pid}`;

export const productsRoute = '/store/products';
export const storeRoute = '/store/products';

export const isProductRoute = (route) => {
    return route.startsWith(productsRoute);
}

export const brandRoute = '/store/brands';
export const eventsRoute = '/events';
export const paletteRoute = '/palette';

export const newPostRoute = '/palette/new';

export const certCheckRoute = '/cert-check';

export const getCreatorProductRoute = (creatorCode, pid) => {
    return `/s/${creatorCode}/${pid}`;
}

export const getInfluencerProductRoute = (creatorCode, pid) => {
    return `/c/${creatorCode}/${pid}`;
}

export const getStoreProductListRoute = (order) => {
    return `${storeRoute}?order=${order}#product-list`;
};

export const getStoreCategoryRoute = (categoryId) => { return `${storeRoute}?category=${categoryId}` }

export const pouchRoute = '/store/pouch';
export const nonMemberOrderSearchRoute = '/store/find-order';

export const searchRoute = '/search';
export const notificationRoute = '/notification';
export const getSearchRoute = (query) => { return `${searchRoute}?q=${query}` };

export const sellerShopRoute = '/seller-shop';
export const reservationRoute = '/reservation';

export const specialOffersRoute = '/store/special-offers';
export const professionalPriceRoute = '/store/professionals';

/* AUTH */
export const loginRoute = '/auth/sign-in';
export const findEmailRoute = '/auth/find-email';
export const findPasswordRoute = '/auth/find-password';
export const passwordResetDoneRoute = '/auth/password-reset-done';

export const snsSignUpRoute = '/auth/sns-sign-up';
export const signUpRoute = '/auth/sign-up';
export const signUpCompleteRoute = '/auth/sign-up-complete';

/* MYPAGE */
export const myPageRoute = '/mypage';
export const faqRoute = '/mypage/faq';
export const productInquiriesRoute = '/mypage/product-inquiries';
export const inquiryRoute = '/mypage/inquiries';
export const myInfoRoute = '/mypage/my-info';
export const addressRoute = '/mypage/addresses';
export const refundAccountRoute = '/mypage/refund-account';
export const proVerificationRoute = '/mypage/verify-pro';
export const creatorStatsRoute = '/mypage/creator-stats';
export const ordersRoute = '/mypage/orders';
export const cancelRoute = '/mypage/cancels';
export const reviewRoute = '/mypage/reviews';
export const getReviewGroupRoute = (groupId) => { return `/mypage/reviews/groups/${groupId}` }
export const likeRoute = '/mypage/likes';
export const couponsRoute = '/mypage/coupons';
export const pointsRoute = '/mypage/point-logs';
export const withdrawRoute = '/mypage/withdraw';
export const withdrawCompleteRoute = '/mypage/withdraw-complete';
export const rewardsRoute = '/rewards';

/* DOCS */
export const marketingAgreementRoute = '/marketing-agreement';
export const termsOfServiceRoute = '/terms-of-service';
export const privacyPolicyRoute = '/privacy-policy';
export const refundPolicyRoute = '/refund-policy';
export const shippingPolicyRoute = '/shipping-policy';
export const offerwallPrivacyPolicyRoute = '/offerwall-privacy-policy';

export const sellerDashboardRoute = '/mypage/seller-dashboard';
export const doublePaletteRoute = '/palette/double';

export const getFailRoute = (ordNum, locale) => {
    return `/store/orders/${ordNum}/fail`;
}

export const getSuccessRoute = (ordNum, locale) => {
    // if (locale === 'en') {
    //     return `/en/store/orders/${ordNum}/success`;
    // }
    return `/store/orders/${ordNum}/success`;
}

export const getCheckoutRoute = (ordNum) => {
    return `/store/orders/${ordNum}/checkout`;
};

export const getCreatorPaletteRoute = (code) => {
    return `/s/${code}?tab=palette`
}

export const getShortsRoute = (pid) => {
    return `/palette/shorts/${pid}`;
};

export const getSinglePaletteRoute = (query, i) => {
    return `/palette/single?q=${query}&i=${i}`;
};

export const getDoublePaletteRoute = (query) => {
    return `/palette/double?q=${query}`;
};

export const getGiftRoute = (orderNumber) => {
    return `/gift/${orderNumber}`;
};

export const getGiftReceiveRoute = (orderNumber) => {
    return `/gift/${orderNumber}/detail`;
};

export const getGiftReceiveDetailRoute = (orderNumber) => {
    return `/gift/${orderNumber}/detail`;
};

export const fullUrl = (path) => {
    return `${SITE_URL}${path}`;
}

// ERROR CODE
export const PAYMENT_FAIL_REASON_IAMPORT_FAIL = 1000;
export const PAYMENT_FAIL_REASON_INTERNAL_SERVER_ERROR = 500;
export const PAYMENT_FAIL_ALREADY_DONE = 409;
export const PAYMENT_FAIL_PAYMENT_MISMATCH = 429;
export const PAYMENT_FAIL_ACCESS_DENIED = 403;

export const getProductRoute = (productId) => { return `${productsRoute}/${productId}` }
export const getProductRouteFromProduct = (product) => {
    return product.is_shop_reservation ? getReservationProductRoute(product.id) : getProductRoute(product.id);
};
export const getBrandRoute = (brandId) => { return `${brandRoute}/${brandId}` }
export const getOrderDetailRoute = (orderNumber) => { return `${ordersRoute}/${orderNumber}` }
export const getRefundDetailRoute = (orderNumber, refundId) => { return `${ordersRoute}/${orderNumber}/refunds/${refundId}` }
export const getExchangeDetailRoute = (orderNumber, exchangeId) => { return `${ordersRoute}/${orderNumber}/exchanges/${exchangeId}` }
export const getRefundRequestRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/refund-order/1` }

export const getExchangeOrderFirstStepRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/exchange-order/1` }
export const getExchangeOrderSecondStepRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/exchange-order/2` }
export const getExchangeOrderDoneRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/exchange-order/done` }

export const getRefundOrderFirstStepRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/refund-order/1` }
export const getRefundOrderSecondStepRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/refund-order/2` }
export const getRefundOrderDoneRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/refund-order/done` }

export const getCancelOrderRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/cancel-order/1` }
export const getCancelDoneRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/cancel-order/done` }
export const getCancelDetailRoute = (orderNumber, optionId) => { return `${ordersRoute}/${orderNumber}/options/${optionId}/cancel-detail` }


/* EVENT */
export const getEventApplyRoute = (eventId) => `${eventsRoute}/${eventId}/apply`;
export const getEventRoute = (eventId) => `${eventsRoute}/${eventId}`;
export const getEventApplyDoneRoute = (eventId) => `${eventsRoute}/${eventId}/done`;

export const getPostRoute = (postId) => { return `${paletteRoute}/${postId}` }
export const getPostEditRoute = (postId) => { return `${paletteRoute}/${postId}/edit`}

export const getCreatorRoute = (creatorCode) => { return `/s/${creatorCode}` }
export const getInfluencerRoute = (creatorCode) => { return `/c/${creatorCode}` }


export const sellerShopGuideRoute = '/events/sellershop-guide';


export const NAVBAR_ID = 'navBarContainer';

export const KAKAO_INQUIRY_URL = "https://pf.kakao.com/_xownxob/chat";

export const COUNTRY_CODE_TO_BRAND_INFO = {
    'KR': {
        'AOU': 26,
        'ANAZE': 3,
        'A.CHI': 827,
        'FLIK': 826,
        'HAUA': 1093,
        'GLOW': 332,
        'ADLONG': 466,
        'HESUL': 1222,
        'COLURE': 4,
    },
    'US': {
        'AOU': 4,
        'ANAZE': 2,
        'A.CHI': 10,
        'FLIK': 9,
        'HAUA': 11,
        'GLOW': 5,
        'ADLONG': 7,
        'HESUL': 8,
        'COLURE': 3,
    }
}

export const generateSEOLink = (hrefWithParams, currentCountryCode) => {
    if (COUNTRY_CODE === KOREA_COUNTRY_CODE) {
        return <link rel={"canonical"} href={`${SITE_URL}${hrefWithParams}`} />
    } else {
        const links = COUNTRY_CODE_TO_SUPPORTED_COUNTRY_CODES[COUNTRY_CODE].filter(
            countryCode => countryCode !== currentCountryCode.toUpperCase()
        ).map(a => a.toLowerCase()).map((countryCode) => {
            const props = {
                rel: 'alternate',
                hreflang: 'en-' + countryCode.toUpperCase(),
                href: `${SITE_URL}/${countryCode.toLowerCase()}${hrefWithParams}`,
            }

            return <link {...props}/>
        });

        links.unshift(<link rel={"canonical"} href={`${SITE_URL}/${currentCountryCode.toLowerCase()}${hrefWithParams}`} />)

        return links;
    }
};