
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import styles from "pages/mypage/myInfo.module.scss";
import utilStyles from "styles/utils.module.scss";
import TitleBar from "components/layout/TitleBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "context/AuthContext";
import { Axios } from "api";
import { AUTH_TYPE_APPLE, AUTH_TYPE_EMAIL, AUTH_TYPE_KAKAO, AUTH_TYPE_NAVER, certCheckRoute, IAMPORT_STORE_ID, marketingAgreementRoute, SITE_URL, withdrawRoute } from "common/const";
import Loading from "components/Loading";
import NaverLogo from "images/icons/naver.svg";
import KakaoLogo from "images/icons/kakao.svg";
import AppleLogo from "images/icons/apple_circle.svg";
import GoogleLogo from "images/icons/google.svg";
import FullButton from "components/buttons/FullButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { KEY_NICKNAME, KEY_NICKNAME_CONTAINS_ALPHABET, KEY_NICKNAME_EXISTS, KEY_NICKNAME_LEGAL, KEY_PASSWORD, KEY_PASSWORD_CONFIRM, KEY_PASSWORD_CONTAINS_ALPHABET, KEY_PASSWORD_CONTAINS_NUMBER, KEY_PASSWORD_CONTAINS_SPECIAL, NICKNAME_ERROR_FIELDS, PASSWORD_ERROR_FIELDS, REGEX_CONTAINS_ALPHABET, REGEX_CONTAINS_DIGIT, REGEX_CONTAINS_SPECIAL, REGEX_NICKNAME_LEGAL } from "pages/auth/sign-up";
import TextInput from "components/input/TextInput";
import CheckedInputError from "components/input/CheckedInputError";
import classNames from "classnames";
import IamportLayout from "components/layout/IamportLayout";
import BulletText from "components/BulletText";
import { customConfirm, dashedPhoneNumber, getLocalStorageCountryCode, isApp, onFileSelect } from "common/utils";
import CheckboxText from "components/input/CheckboxText";
import Link from "next/link";
import IamportScript from "components/IamportScript";
import { captureException, captureMessage } from "@sentry/nextjs";
import DefaultProfileIcon from "images/icons/default_profile.svg";
import Image from "next/image";
import PushContext from "context/PushContext";
import { v4 } from "uuid";
import useTranslation from "next-translate/useTranslation";
const KEY_OLD_PASSWORD = 'old_password';
export const PasswordEditor = (props) => {
    const { t } = useTranslation('mypage');
    const { isEditModeEnabled, isOldPasswordRequired, optionalData, hideLabel, noMargin } = props;
    const onSuccess = props.onSuccess || function () { };
    const [isEditMode, setIsEditMode] = useState(!isEditModeEnabled);
    const [isLoading, setIsLoading] = useState(false);
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            [KEY_PASSWORD]: '',
            [KEY_PASSWORD_CONFIRM]: '',
            [KEY_OLD_PASSWORD]: ''
        },
        validationSchema: yup.object({
            [KEY_OLD_PASSWORD]: isOldPasswordRequired ? yup.string().required(t('myInfo.password.required')) : undefined,
            [KEY_PASSWORD]: yup.string().required().min(8).max(16),
            [KEY_PASSWORD_CONFIRM]: yup.string().when(KEY_PASSWORD, (password, schema) => {
                return schema.oneOf([password]);
            }),
            [KEY_PASSWORD_CONTAINS_ALPHABET]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_ALPHABET)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_NUMBER]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_DIGIT)) {
                    return schema.oneOf([true]).required();
                }
            }),
            [KEY_PASSWORD_CONTAINS_SPECIAL]: yup.boolean().when(KEY_PASSWORD, (password, schema) => {
                if (!password)
                    return schema.required();
                if (!password.match(REGEX_CONTAINS_SPECIAL)) {
                    return schema.oneOf([true]).required();
                }
            })
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const data = Object.assign(JSON.parse(JSON.stringify(values)), optionalData);
                delete data[KEY_PASSWORD_CONFIRM];
                const res = await Axios.post('v1/auth/change-password/', data);
                if (res.status < 400) {
                    onSuccess();
                    if (isEditModeEnabled)
                        setIsEditMode(false);
                    resetForm();
                }
                else if (res.status === 403) {
                    setFieldError(KEY_OLD_PASSWORD, t('myInfo.password.check'));
                }
                else {
                    if (res.data.display_message) {
                        alert(res.data.display_message);
                    }
                    else {
                        captureMessage(JSON.stringify(res.data));
                        alert(t('myInfo.changePasswordFailed'));
                    }
                }
            }
            catch (e) {
                captureException(e);
                alert(t('myInfo.changePasswordFailed'));
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const { errors, values, resetForm, setFieldValue, touched, setFieldTouched, handleSubmit, setTouched, setFieldError } = formik;
    const onSubmit = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoPasswordEditorSubmitClick');
        handleSubmit();
    };
    const onCancel = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoPasswordEditorCancelClick');
        if (isEditModeEnabled)
            setIsEditMode(false);
        resetForm();
    };
    const onChangeClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoPasswordEditorChangeClick');
        setIsEditMode(true);
    };
    return (<div className={classNames(styles.editorContainer, isEditMode ? styles.editorContainerActive : undefined)} style={{ marginTop: noMargin ? 0 : undefined }}>
            <div>
                {!hideLabel &&
            <span className={styles.sectionSubtitle}>{t('myInfo.password.title')}</span>}
                {!isEditMode && <span className={styles.content} style={{ fontSize: 13 }}>●●●●●●●●●●</span>}
            </div>
            {!isEditMode ?
            <div className={styles.editorButtonWrapper}>
                        <FullButton white height={36} title={t('myInfo.change')} fontSize={15} nonBold onClick={onChangeClick}/>
                    </div>
            :
                <div style={{ marginTop: 4, width: '100%' }}>
                        {isOldPasswordRequired &&
                        <>
                                <TextInput onEnter={handleSubmit} readonly={isLoading} error={touched[KEY_OLD_PASSWORD] && errors[KEY_OLD_PASSWORD]} onBlur={() => setFieldTouched(KEY_OLD_PASSWORD)} value={values[KEY_OLD_PASSWORD]} onChange={e => setFieldValue(KEY_OLD_PASSWORD, e)} type="password" placeholder={t('myInfo.password.current')} maxLength={16}/>
                                {(!!errors[KEY_OLD_PASSWORD] && touched[KEY_OLD_PASSWORD]) &&
                                <div style={{ marginTop: 6 }}>
                                        <CheckedInputError isValid={false} message={errors[KEY_OLD_PASSWORD]}/>
                                    </div>}
                                <div style={{ height: 12 }}/>
                            </>}
                        <TextInput onEnter={handleSubmit} readonly={isLoading} error={touched[KEY_PASSWORD] && PASSWORD_ERROR_FIELDS.filter(field => errors[field]).length > 0} onBlur={() => setFieldTouched(KEY_PASSWORD)} value={values[KEY_PASSWORD]} onChange={e => setFieldValue(KEY_PASSWORD, e)} type="password" placeholder={t('myInfo.password.new')} maxLength={16}/>
                        {(!!values[KEY_PASSWORD] || touched[KEY_PASSWORD]) &&
                        <div style={{ marginTop: 6 }}>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_ALPHABET]} message={t('myInfo.passwordRules.includeEnglish')}/>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_NUMBER]} message={t('myInfo.passwordRules.includeNumber')}/>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONTAINS_SPECIAL]} message={t('myInfo.passwordRules.includeSpecial')}/>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD]} message={t('myInfo.passwordRules.length')}/>
                            </div>}
                        <div style={{ height: 12 }}/>
                        <TextInput onEnter={onSubmit} readonly={isLoading} error={touched[KEY_PASSWORD_CONFIRM] && !!errors[KEY_PASSWORD_CONFIRM]} onBlur={() => setFieldTouched(KEY_PASSWORD_CONFIRM)} value={values[KEY_PASSWORD_CONFIRM]} onChange={e => setFieldValue(KEY_PASSWORD_CONFIRM, e)} type="password" placeholder={t('myInfo.password.confirm')} maxLength={16}/>
                        {!!values[KEY_PASSWORD_CONFIRM] &&
                        <div style={{ marginTop: 6 }}>
                                <CheckedInputError isValid={!errors[KEY_PASSWORD_CONFIRM]} message={t('myInfo.passwordRules.match')}/>
                            </div>}
                        <div style={{ height: 24 }}/>
                        <div className={styles.buttonsContainer}>
                            {isEditModeEnabled &&
                        <div className={styles.submitButtonWrapper}>
                                    <FullButton disabled={isLoading} height={48} fontSize={16} light title={t('myInfo.changeCancel')} onClick={onCancel}/>
                                </div>}
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} title={isEditModeEnabled ? t('myInfo.save') : t('myInfo.confirm')} onClick={onSubmit}/>
                            </div>
                        </div>
                    </div>}
        </div>);
};
export const ProfileImageEditor = (props) => {
    const { t } = useTranslation('mypage');
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const { horizontal } = props;
    const onChange = props.onChange || function () { };
    const [isLoading, setIsLoading] = useState(false);
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const uploadProfileImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        try {
            setIsLoading(true);
            const res = await Axios.post('v1/user/profile-image/', formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (res.status < 400) {
                userContext.setUser(res.data);
                onChange(res.data.profile_image_url);
            }
            else {
                alert(t('myInfo.profileImage.uploadFailed'));
            }
        }
        catch (e) {
            alert(t('myInfo.profileImage.uploadFailed'));
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    const onDeleteProfileImage = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoDeleteProfileImageClick');
        customConfirm(t('myInfo.deleteProfileImageConfirm'), t('myInfo.deleteProfileImageButton'), t('myInfo.cancel'), () => {
            typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoDeleteProfileConfirmClick');
            onDeleteProfileImageConfirm();
        }, () => {
            typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoDeleteProfileCancelClick');
        });
    };
    const onDeleteProfileImageConfirm = async () => {
        try {
            setIsLoading(true);
            const res = await Axios.delete(`v1/user/profile-image/`);
            if (res.status < 400) {
                userContext.setUser(res.data);
                onChange(res.data.profile_image_url);
            }
            else {
                alert(t('myInfo.profileImage.deleteFailed'));
            }
        }
        catch (e) {
            alert(t('myInfo.profileImage.deleteFailed'));
            captureException(e);
        }
        finally {
            setIsLoading(false);
        }
    };
    if (!user)
        return <div></div>;
    const onImageInputClick = (e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoProfileImageChangeTextClick');
        if (isLoading) {
            return;
        }
        e.target.value = null;
    };
    return (<div className={classNames(styles.profileImageEditor, horizontal ? utilStyles.flexRow : utilStyles.flexCol)}>
            {user.profile_image_url
            ?
                <div className={classNames(styles.profileImage, styles.profileImageBorder, horizontal ? styles.profileImageHorizontal : undefined)}>
                        <Image unoptimized src={user.profile_image_url} layout="fill"/>
                        <div className={styles.profileImageDelete} onClick={onDeleteProfileImage}>{t('myInfo.deleteProfileImage')}</div>
                    </div>
            : <DefaultProfileIcon className={classNames(styles.profileImage, horizontal ? styles.profileImageHorizontal : undefined)} viewBox="0 0 32 32"/>}
            <div className={classNames(styles.profileNicknameContainer, horizontal ? styles.profileNicknameContainerHorizontal : undefined)}>
                <div className={classNames(styles.profileNicknameWrapper, horizontal ? styles.profileNicknameWrapperHorizontal : undefined)}>
                    <span className={classNames(styles.profileNickname, horizontal ? styles.profileNicknameHorizontal : undefined)}>{user.nickname}</span>
                </div>
                <div className={classNames(styles.profileEditText, utilStyles.cursorPointer)}>
                    <span>{t('myInfo.changeProfileImage')}</span>
                    <input type="file" id="img" name="img" accept="image/*" disabled={isLoading} className={styles.imageInputInvisible} onChange={onFileSelect(uploadProfileImage)} onClick={onImageInputClick}/>
                </div>
            </div>
        </div>);
};
export const NicknameForm = ({ formik, isLoading }) => {
    const { t } = useTranslation('mypage');
    const { errors, values, setFieldValue, touched, setFieldTouched, resetForm, handleSubmit, setTouched, setFieldError } = formik;
    const checkNickname = async (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
        try {
            const res = await Axios.get('v1/auth/check-nickname/', { params: { nickname: nickname } });
            console.log(res);
            if (res.status === 409) {
                setFieldValue(KEY_NICKNAME_EXISTS, t('myInfo.nickname.inUse'));
            }
            else {
                setFieldValue(KEY_NICKNAME_EXISTS, '');
            }
        }
        catch (e) {
            captureException(e);
            setFieldValue(KEY_NICKNAME_EXISTS, '');
        }
    };
    const onChangeNickname = (nickname) => {
        if (!nickname) {
            setFieldValue(KEY_NICKNAME, '');
        }
        const lowerNick = nickname.toLowerCase();
        if (lowerNick.match(REGEX_NICKNAME_LEGAL)) {
            setFieldValue(KEY_NICKNAME, lowerNick);
        }
    };
    const nickNameError = !!values[KEY_NICKNAME_EXISTS] || (!!touched[KEY_NICKNAME] && NICKNAME_ERROR_FIELDS.filter(field => errors[field]).length > 0);
    return (<>
            <TextInput readonly={isLoading} error={nickNameError} value={values[KEY_NICKNAME]} onBlur={() => setFieldTouched(KEY_NICKNAME)} onChangeThrottled={checkNickname} onChange={onChangeNickname} placeholder={t('myInfo.nickname.placeholder')} maxLength={20}/>
            {(!!values[KEY_NICKNAME] || touched[KEY_NICKNAME]) &&
            <div style={{ marginTop: 6 }}>
                    <CheckedInputError isValid={!errors[KEY_NICKNAME_CONTAINS_ALPHABET]} message={t('myInfo.passwordRules.includeEnglish')}/>
                    <CheckedInputError isValid={!errors[KEY_NICKNAME]} message={t('myInfo.nickname.length')}/>
                    {values[KEY_NICKNAME_EXISTS] && <CheckedInputError isValid={false} message={t('myInfo.nickname.inUse')}/>}
                </div>}
        </>);
};
NicknameForm.initialValue = {
    [KEY_NICKNAME]: '',
    [KEY_NICKNAME_EXISTS]: ''
};
NicknameForm.validationSchema = {
    [KEY_NICKNAME]: yup.string().required().min(2).max(20),
    [KEY_NICKNAME_CONTAINS_ALPHABET]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
        if (!nickname)
            return schema.required();
        if (!nickname.match(REGEX_CONTAINS_ALPHABET)) {
            return schema.oneOf([true]).required();
        }
    }),
    [KEY_NICKNAME_LEGAL]: yup.boolean().when(KEY_NICKNAME, (nickname, schema) => {
        if (!nickname)
            return schema;
        if (!nickname.match(REGEX_NICKNAME_LEGAL)) {
            return schema.oneOf([true]).required();
        }
    })
};
NicknameForm.preSubmit = (values, callback) => {
    const actualCallback = callback || function () { };
    if (values[KEY_NICKNAME_EXISTS]) {
        actualCallback();
        alert('사용중인 유저네임입니다.');
        return false;
    }
    return true;
};
const NicknameEditor = () => {
    const { t } = useTranslation('mypage');
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const formik = useFormik({
        enableReinitialize: false,
        initialValues: NicknameForm.initialValue,
        validationSchema: yup.object(NicknameForm.validationSchema),
        onSubmit: async (values) => {
            if (!NicknameForm.preSubmit(values, () => {
            })) {
                return;
            }
            const data = JSON.parse(JSON.stringify(values));
            delete data[KEY_NICKNAME_EXISTS];
            setIsLoading(true);
            try {
                const res = await Axios.post('v1/auth/change-nickname/', data);
                if (res.status < 400) {
                    const user = res.data;
                    userContext.setUser(user);
                    alert(t('myInfo.nicknameChange.success'));
                    setIsEditMode(false);
                    resetForm();
                }
                else {
                    if (res.data.display_message) {
                        alert(res.data.display_message);
                    }
                    else {
                        captureMessage(JSON.stringify(res.data));
                        alert(t('myInfo.nicknameChange.error'));
                    }
                }
            }
            catch (e) {
                captureException(e);
                alert(t('myInfo.nicknameChange.error'));
            }
            finally {
                setIsLoading(false);
            }
        }
    });
    const onChangeTextClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoNicknameChangeClick');
        setIsEditMode(true);
    };
    const onCancelClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoNicknameChangeCancelClick');
        setIsEditMode(false);
        resetForm();
    };
    const onSubmitClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoNicknameChangeSubmitClick');
        handleSubmit();
    };
    const { resetForm, handleSubmit } = formik;
    return (<div className={classNames(styles.editorContainer, isEditMode ? styles.editorContainerActive : undefined)}>
            <div>
                <span className={styles.sectionSubtitle}>{t('myInfo.nickname.label')}</span>
                {!isEditMode &&
            <span className={styles.content}>{user.nickname}</span>}
            </div>
            {!isEditMode ?
            <div className={styles.editorButtonWrapper}>
                        <FullButton white height={36} title={t('myInfo.change')} fontSize={15} nonBold onClick={onChangeTextClick}/>
                    </div>
            :
                <div style={{ marginTop: 4, width: '100%' }}>
                        <NicknameForm formik={formik} isLoading={isLoading}/>
                        <div style={{ height: 24 }}/>
                        <div className={styles.buttonsContainer}>
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} light title={t('myInfo.changeCancel')} onClick={onCancelClick}/>
                            </div>
                            <div className={styles.submitButtonWrapper}>
                                <FullButton disabled={isLoading} height={48} fontSize={16} title={t('myInfo.save')} onClick={onSubmitClick}/>
                            </div>
                        </div>
                    </div>}
        </div>);
};
export const getSNSIcon = (auth_type) => {
    if (auth_type === AUTH_TYPE_NAVER) {
        return <NaverLogo viewBox="0 0 48 48" className={styles.snsLogo}/>;
    }
    else if (auth_type === AUTH_TYPE_KAKAO) {
        return <KakaoLogo viewBox="0 0 48 48" className={styles.snsLogo}/>;
    }
    else if (auth_type === AUTH_TYPE_APPLE) {
        return <AppleLogo viewBox="0 0 48 48" className={styles.snsLogo}/>;
    }
    else {
        return <GoogleLogo viewBox="0 0 48 48" className={styles.snsLogo}/>;
    }
};
export const useSelfVerification = ({ confirmMessage, direct, redirect }) => {
    const { t } = useTranslation('mypage');
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const router = useRouter();
    const iamPortVerify = useCallback(() => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoEditSelfVerificationInfoClick', {
            isVerified: Boolean(user?.rrn)
        });
        if (confirmMessage) {
            customConfirm(confirmMessage, t('myInfo.confirm'), t('myInfo.cancel'), () => {
                iamPortVerifyConfirm();
            }, () => {
            });
        }
        else {
            iamPortVerifyConfirm();
        }
    }, [user, router, redirect]);
    const iamPortVerifyConfirm = () => {
        let redirectURL = redirect || `${SITE_URL}${router.asPath}`;
        const redirectHost = redirectURL.split('?')[0];
        let redirectQuery = redirectURL.split('?')[1] || '';
        redirectQuery = Object.fromEntries(new URLSearchParams(redirectQuery));
        try {
            window.IMP.certification;
        }
        catch {
            return;
        } // Make sure window.IMP is set
        window.IMP.init(IAMPORT_STORE_ID);
        window.IMP.certification({
            merchant_uid: `${user?.email || v4()}_${Date.now()}`,
            popup: false,
            m_redirect_url: direct ? redirectURL : `${SITE_URL}${certCheckRoute}?redirect=${encodeURIComponent(redirectURL)}`
        }, async (result) => {
            if (result.success) {
                if (direct) {
                    router.push({
                        pathname: redirectHost,
                        query: Object.assign({}, redirectQuery, { imp_uid: result.imp_uid })
                    }, undefined, { shallow: false });
                }
                else {
                    router.push({
                        pathname: `${SITE_URL}${certCheckRoute}`,
                        query: {
                            redirect: encodeURIComponent(redirectURL),
                            imp_uid: result.imp_uid
                        }
                    }, undefined, { shallow: false });
                }
            }
            else {
                alert(t('myInfo.verificationFailed', { errorMsg: result.error_msg }));
                router.push(`${SITE_URL}${router.asPath}`);
            }
        });
    };
    return iamPortVerify;
};
export const SelfVerificationButton = (props) => {
    const { setIsLoading, isLoading, children, redirect, confirmMessage, direct } = props;
    const onSuccess = props.onSuccess || function () { };
    const userContext = useContext(UserContext);
    const verify = useSelfVerification({ confirmMessage, direct, redirect });
    return (<div onClick={!isLoading ? verify : () => { }} style={props.style}>
            <IamportScript />
            {children}
        </div>);
};
const SelfVerificationEditor = () => {
    const { t } = useTranslation('mypage');
    const userContext = useContext(UserContext);
    const user = userContext.user;
    const [isLoading, setIsLoading] = useState(false);
    return (<>
            {!!user.name &&
            <>
                    <div className={styles.selfVerificationSection}>
                        <span className={styles.sectionSubtitle}>{t('myInfo.name')}</span>
                        <span className={styles.content}>{user.name}</span>
                    </div>
                    <div className={styles.selfVerificationSection}>
                        <span className={styles.sectionSubtitle}>{t('myInfo.phoneNumber')}</span>
                        <span className={styles.content}>
                            {user.phone_number ?
                    dashedPhoneNumber(user.phone_number)
                    :
                        <span className={classNames(utilStyles.errorText, utilStyles.medium)}>
                                        {t('myInfo.phoneNumberChanged')}
                                    </span>}
                        </span>
                    </div>
                </>}
            <SelfVerificationButton isLoading={isLoading} setIsLoading={setIsLoading}>
                <FullButton disabled={isLoading} height={44} fontSize={16} title={t('myInfo.selfVerification', { action: !!user.name ? t('myInfo.update') : t('myInfo.register') })}/>
            </SelfVerificationButton>

            <div className={styles.bulletTextWrapper}>
                <BulletText>{t('myInfo.selfVerificationInfo')}</BulletText>
                <BulletText>{t('myInfo.nameChangeInfo')}</BulletText>
            </div>

        </>);
};
const KEY_SMS_MARKETING_AGREEMENT = 'is_sms_marketing_agreed';
const KEY_EMAIL_MARKETING_AGREEMENT = 'is_email_marketing_agreed';
export const KEY_PUSH_MARKETING_AGREEMENT = 'is_push_marketing_agreed';
const MarketingAgreementEditor = () => {
    const { t } = useTranslation('mypage');
    const [isLoading, setIsLoading] = useState(false);
    const userContext = useContext(UserContext);
    const pushContext = useContext(PushContext);
    const user = userContext.user;
    const countryCode = getLocalStorageCountryCode();
    const onChangeAgreement = async (key) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoMarketingAgreementEditorAgreeClick', {
            key
        });
        const currVal = user[key];
        try {
            setIsLoading(true);
            const res = await Axios.patch('v1/user/marketing-agreement/', { [key]: !currVal });
            if (res.status < 400) {
                userContext.setUser(res.data);
                console.log(res.data);
            }
            else {
                alert(t('myInfo.agreementError'));
            }
        }
        catch (e) {
            captureException(e);
            alert(t('myInfo.agreementError'));
        }
        finally {
            setIsLoading(false);
        }
    };
    const showDetailClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoMarketingAgreementEditorShowDetailClick');
    };
    const isPushOn = pushContext.isDevicePushOn && pushContext.isMarketingAgreed;
    return (<div>
            {countryCode === 'KR' &&
            <div className={styles.agreementCheckboxWrapper}>
                    <CheckboxText checked={user[KEY_SMS_MARKETING_AGREEMENT]} isLoading={isLoading} onClick={() => {
                    onChangeAgreement(KEY_SMS_MARKETING_AGREEMENT);
                }}>
                        <span className={styles.agreementCheckboxText}>{t('myInfo.marketingAgreement.sms')}</span>
                    </CheckboxText>
                </div>}
            <div className={styles.agreementCheckboxWrapper}>
                <CheckboxText checked={user[KEY_EMAIL_MARKETING_AGREEMENT]} isLoading={isLoading} onClick={() => {
            onChangeAgreement(KEY_EMAIL_MARKETING_AGREEMENT);
        }}>
                    <span className={styles.agreementCheckboxText}>{t('myInfo.marketingAgreement.email')}</span>
                </CheckboxText>
            </div>
            {isApp() &&
            <div className={styles.agreementCheckboxWrapper}>
                    <CheckboxText checked={isPushOn} onClick={() => {
                    if (pushContext.isLoading)
                        return;
                    typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoMarketingAgreementEditorAgreeClick', {
                        key: KEY_PUSH_MARKETING_AGREEMENT
                    });
                    pushContext.sendPushAgreementRequest(!isPushOn);
                }}>
                        <span className={styles.agreementCheckboxText}>{t('myInfo.marketingAgreement.push')}</span>
                    </CheckboxText>
                </div>}
            <span className={styles.agreementWarning}>{t('myInfo.marketingAgreement.warning')}</span>
            <Link href={marketingAgreementRoute}>
                <a target="_blank" className={styles.agreementSeeMore} onClick={showDetailClick}>
                    {t('myInfo.marketingAgreement.showDetails')}
                </a>
            </Link>
        </div>);
};
const MyInfo = () => {
    const { t } = useTranslation('mypage');
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);
    const router = useRouter();
    const [isInfoLoading, setIsInfoLoading] = useState(true);
    const userContext = useContext(UserContext);
    const user = userContext.user;
    useEffect(async () => {
        try {
            await userContext.fetchUser();
        }
        catch (e) { }
        finally {
            setIsInfoLoading(false);
        }
    }, []);
    const onWithdrawClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('MyPageMyInfoWithdrawClick');
    };
    const countryCode = getLocalStorageCountryCode();
    return (<>
            <div className={classNames(styles.container, utilStyles.pageContainer)}>
                <div className={classNames(utilStyles.sidePadding, utilStyles.topSticky, utilStyles.whiteBackground)}>
                    <TitleBar title={t('myInfo.title')} isBack close={() => router.back()}/>
                </div>
                {!isMobile &&
            <>
                        {/*<MyInfoCard />*/}
                        <div style={{ height: isLarge ? 20 : 20 }}/>
                    </>}
                {isInfoLoading ?
            <Loading />
            :
                (!!userContext.user
                    ?
                        <>
                                    <div className={styles.section}>
                                        <ProfileImageEditor />
                                    </div>
                                    {isMobile && <div className={utilStyles.borderUnderLine}/>}
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>{t('myInfo.loginInfo')}</span>
                                        {user.auth_type !== AUTH_TYPE_APPLE &&
                                <>
                                                <span className={styles.sectionSubtitle}>{t('myInfo.idEmail')}</span>
                                                <span className={styles.content}>{user.email}</span>
                                            </>}
                                        {user.auth_type === AUTH_TYPE_EMAIL ?
                                <PasswordEditor isEditModeEnabled isOldPasswordRequired onSuccess={() => alert(t('myInfo.changeSuccess'))}/>
                                :
                                    <div className={styles.snsInfoContainer}>
                                                    <div className={utilStyles.noLineHeight}>
                                                        {getSNSIcon(user.auth_type)}
                                                        <span className={styles.snsType}>{`${t(`common:sns.${user.auth_type}`)}${t('myInfo.snsLogin')}`}</span>
                                                    </div>
                                                    <span className={styles.snsDescription}>{t('myInfo.snsLoginDescription')}</span>
                                                </div>}
                                        <NicknameEditor />
                                    </div>
                                    {isMobile && <div className={utilStyles.mobileBorder}/>}
                                    {countryCode === 'KR' &&
                                <div className={styles.section}>
                                            <span className={styles.sectionTitle}>{t('myInfo.basicInfo')}</span>
                                            <SelfVerificationEditor />
                                        </div>}
                                    {isMobile && <div className={utilStyles.mobileBorder}/>}
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>{t('myInfo.marketingAgreement.title')}</span>
                                        <MarketingAgreementEditor />
                                    </div>
                                    {isMobile && <div className={utilStyles.mobileBorder}/>}
                                    <div className={styles.withdrawContainer}>
                                        <span className={styles.withdrawQuestion}>{t('myInfo.withdraw.question')}</span>
                                        <Link href={withdrawRoute}>
                                            <a className={styles.withdrawLink} onClick={onWithdrawClick}>{t('myInfo.withdraw.link')}</a>
                                        </Link>
                                    </div>
                                </>
                    :
                        <div dangerouslySetInnerHTML={{ __html: t('myInfo.loadError') }}/>)}
            </div>
        </>);
};
MyInfo.getLayout = IamportLayout;
async function getStaticProps(context) {
    return {
        props: {
            protected: true
        }
    };
}
export default MyInfo;

    async function __Next_Translate__getStaticProps__195d0eaaa21__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/mypage/my-info',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195d0eaaa21__ as getStaticProps }
  