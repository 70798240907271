import {COUNTRY_CODE, LOCALE} from "common/const";

export const KOREA_COUNTRY_CODE = 'KR';
export const US_COUNTRY_CODE = 'US';
export const JAPAN_COUNTRY_CODE = 'JP';
export const GLOBAL_COUNTRY_CODE = 'GLOBAL';

export const getDomainByCountryCode = (countryCode) => {
    let domain;
    switch (countryCode) {
        case KOREA_COUNTRY_CODE:
            domain = process.env.KR_DOMAIN;
            break;
        case US_COUNTRY_CODE:
            domain = process.env.US_DOMAIN;
            break;
        case JAPAN_COUNTRY_CODE:
            domain = process.env.JP_DOMAIN;
            break;
        case GLOBAL_COUNTRY_CODE:
            domain = process.env.GLOBAL_DOMAIN;
            break;
        default:
            domain = process.env.US_DOMAIN;
            break;
    }
    return domain;
};


const getCountryCodeFromHost = (host) => {
    console.log('getCountryCodeFromHost', host);
    let countryCode;

    if (host === process.env.GLOBAL_DOMAIN) {
        countryCode = US_COUNTRY_CODE;
    } else if (host === process.env.US_DOMAIN) {
        countryCode = US_COUNTRY_CODE;
    } else if (host === process.env.KR_DOMAIN) {
        countryCode = KOREA_COUNTRY_CODE;
    } else if (host === process.env.JP_DOMAIN) {
        countryCode = JAPAN_COUNTRY_CODE;
    } else {
        countryCode = KOREA_COUNTRY_CODE;
    }

    return countryCode;
};

export const getUrlCountryCode = () => {
    if (typeof window === 'undefined') {
        return COUNTRY_CODE;
    }
    if (COUNTRY_CODE === KOREA_COUNTRY_CODE) {
        return KOREA_COUNTRY_CODE;
    }
    const paths = window.location.pathname ? window.location.pathname.split('/') : [];
    const urlCountryCode = paths.length > 1 ? paths[1] : '';
    if (urlCountryCode.length > 2) {
        return COUNTRY_CODE;
    }
    return urlCountryCode;
};

export const getLocalizationInfoInSSR = (context) => {
    const locale = LOCALE;
    const localeCountryCode = context.locale ? context.locale.toUpperCase() : COUNTRY_CODE;
    let countryCode;
    if (["KR", "KO"].includes(localeCountryCode)) {
        countryCode = KOREA_COUNTRY_CODE;
    } else if (["US", "EN"].includes(localeCountryCode)) {
        countryCode = US_COUNTRY_CODE;
    } else {
        countryCode = localeCountryCode;
    }

    console.log('getLocalizationInfoInSSR', countryCode, locale);

    return {
        countryCode,
        locale
    }
};

export const getLocalizationInfoInCSR = () => {
    const urlCountryCode = getUrlCountryCode();
    const locale = LOCALE;
    let countryCode;
    if (urlCountryCode === "") {
        countryCode = COUNTRY_CODE;
    } else if (["us", "en"].includes(urlCountryCode)) {
        countryCode = US_COUNTRY_CODE;
    } else if (["kr", "ko"].includes(urlCountryCode)) {
        countryCode = KOREA_COUNTRY_CODE;
    } else {
        countryCode = urlCountryCode.toUpperCase();
    }
    console.log('getLocalizationInfoInCSR', countryCode, locale);

    return {
        countryCode,
        locale,
    }
};

export const setCountryCodeInCookieSSR = (context) => {
    const {countryCode, locale} = getLocalizationInfoInSSR(context);
    // Get the original cookie string
    const originalCookie = context.req.headers.cookie || '';

    // Parse the cookie string into an object
    const cookies = {};
    originalCookie.split(';').forEach(cookie => {
        const [key, value] = cookie.trim().split('=');
        cookies[key] = value;
    });

    // Modify the cookie value
    cookies['NEXT_COUNTRY'] = countryCode;

    // Reconstruct the cookie string
    const newCookieString = Object.entries(cookies)
        .map(([key, value]) => `${key}=${value}`)
        .join('; ');

    // Set the modified cookie string back to the headers
    context.req.headers.cookie = newCookieString;
    return {countryCode, locale}
};

export const forceCountryCodeInCookieSSR = (context, countryCode) => {
    if (!context.req || !context.req.headers) {
        return;
    }
    const originalCookie = context.req.headers.cookie || '';
    // Modify the cookie value
    // Parse the cookie string into an object
    const cookies = {};
    originalCookie.split(';').forEach(cookie => {
        const [key, value] = cookie.trim().split('=');
        cookies[key] = value;
    });
    cookies['NEXT_COUNTRY'] = countryCode;

    // Reconstruct the cookie string
    const newCookieString = Object.entries(cookies)
        .map(([key, value]) => `${key}=${value}`)
        .join('; ');

    // Set the modified cookie string back to the headers
    context.req.headers.cookie = newCookieString;
    return {countryCode}
};
