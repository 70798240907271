import {useEffect} from "react";
import {KAKAO_CLIENT_ID} from "common/const";
import * as Sentry from "@sentry/nextjs";

const KakaoLoader = () => {
    useEffect(async () => {
        try {
            await import("common/kakao.js");
            if (!Boolean(Kakao?.isInitialized())) {
                Kakao.init(KAKAO_CLIENT_ID);
            }
        } catch (e) {
            Sentry.captureException(e);
        }
    }, []);
    return <div />;
};

export const kakaoShareCallback = (shareTitle, shareLink) => {
    try {
        Kakao.Share.sendDefault({
            objectType: 'text',
            text: shareTitle,
            link: {
                mobileWebUrl: shareLink,
                webUrl: shareLink,
            },
        });
    } catch (e) {
        Sentry.captureException(e);
    }
};

export default KakaoLoader;
