import {COUNTRY_CODE_TO_FLAG_FILE, countryCodeToChoice} from "common/country_list";
import {useMemo} from "react";
import styles from "components/country/CountryImage.module.scss";
import Image from "next/image";
import classNames from "classnames";

const possibleSize = [
    16, 24, 32, 48, 64,
]

export const CountryFlagImage = (props) => {
    const {size, circle, countryCode, showDescription} = props;
    const targetSize = size || 24;

    if (!countryCode) {
        return null;
    }

    const choice = countryCodeToChoice[countryCode];

    return (
        <div className={styles.countryImageRootContainer}>
            <div className={classNames(styles.flagImageCircle, circle ? styles.flagImageCircle : null)}
                 style={{borderRadius: circle ? targetSize: null, width: targetSize, height: targetSize}}>
            {
                <Image src={`https://d1cnx04b8cgzcv.cloudfront.net/media/flags/${COUNTRY_CODE_TO_FLAG_FILE[countryCode]}`}
                       alt={countryCode} key={`flag-${countryCode}`} width={targetSize} height={targetSize}/>
            }
            </div>
            {showDescription && (
                <div className={styles.descriptionContainer}>
                    {`${choice?.description || countryCode}`}
                </div>)
            }
        </div>
    )
}