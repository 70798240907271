import Modal from "components/Modal";
import styles from "components/modal/ProductShareModal.module.scss";
import * as Sentry from "@sentry/nextjs";
import {useRouter} from "next/router";
import ShareButton from "components/ShareButton";
import {getCreatorRoute, getInfluencerRoute, SITE_URL} from "common/const";
import {isMobile} from "common/utils";
import Clipboard from "react-clipboard.js";
import {toast} from "react-toastify";
import {useCallback, useContext, useState} from "react";
import {addToSellerShop} from "api/addToSellerShop";
import UserContext from "context/AuthContext";
import {redirectToLogin} from "common/redirect";
import FullButton from "components/buttons/FullButton";
import useTranslation from "next-translate/useTranslation";

const WebProductShareModalButton = (props) => {
    const {t} = useTranslation('component-productShare-modal');
    const {isSellerShopButton, product, isLoading, setIsLoading, onSuccess, withoutReward} = props;
    const router = useRouter();

    const user = useContext(UserContext).user;
    const isInfluencer = Boolean(user?.is_influencer)

    // const text = isSellerShopButton ? "내 편집샵에 추가하고 링크 복사하기" : "링크 복사하기";
    const text = !withoutReward ? t("copyButtonDescription")
        : t("nonRewardButton");
    const shareLink = (!withoutReward && user) ?
        `${SITE_URL}${isInfluencer ? getInfluencerRoute(user.code) : getCreatorRoute(user.code)}/${product.id}`:
        `${SITE_URL}${router.asPath.split('?')[0]}`;

    const onClick = useCallback((e) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'WebProductShareModalButtonClick',
            {
                isSellerShopButton,
            }
        );

        if (user === undefined && !withoutReward) {
            toast.info(t('wait'));
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        if (user === null && !withoutReward) {
            redirectToLogin(router, true);
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        // if (isLoading) {
        //     return;
        // }
        // setIsLoading(true);
        // await addToSellerShop(product.id, true, false);
        // setIsLoading(false);
    }, [user]);

     const handleSuccess = useCallback(() => {
        if (!user && !withoutReward) {
            return;
        }
        toast.info(
            t('copyButton')
        );
        onSuccess && onSuccess();
     }, [user, onSuccess]);

    return (
        <Clipboard
            onClick={onClick}
            className={styles.clipboard}
            option-text={() => shareLink}
            onSuccess={handleSuccess}
        >
            <div className={styles.shareButton}>
                <FullButton title={text} height={30} fontSize={14} onClick={undefined} sidePadding={12}/>
            </div>
        </Clipboard>
    )
};

const ProductShareModalButton = (props) => {
    const {isSellerShopButton, product, isLoading, setIsLoading} = props;
    const router = useRouter();

    const user = useContext(UserContext).user;

    const text = "링크 공유하기";
    const shareLink = user ?
        `${SITE_URL}${getCreatorRoute(user.code)}/${product.id}`:
        `${SITE_URL}${router.asPath.split('?')[0]}`;

    const onClick = async () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'ProductShareModalButtonClick',
            {
                isSellerShopButton,
            }
        );
        if (!isSellerShopButton) {
            return;
        }
        if (!user) {
            redirectToLogin(router, true);
            return false;
        }
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        await addToSellerShop(product.id, true, t);
        setIsLoading(false);
    };

    return (
        <ShareButton
            onClick={onClick}
            kakaoShareCallback={() => {
            const brandNames = product ? [
                product.brand_name
            ] : [];

            if (
                product && product.ownership_brands &&
                product.ownership_brands.some((x) => x.english_name && x.name !== x.english_name)
            ) {
                brandNames.push(
                    product.ownership_brands
                        ? product.ownership_brands.map((x, i) => x.english_name).join(" X ")
                        : ""
                );
            }

            const brandName = brandNames.join(' / ');

            typeof mixpanel !== 'undefined' && mixpanel.track('ProductDetailKakaoShareButtonClick', {
                productName: product.name,
                productId: product.id,
            });
            const commerce = {
                productName: `[${brandName}] ${product.name}`,
                regularPrice: product.price,
                discountRate: product.discount_rate,
                // discountPrice: product.discounted_price === product.price ? undefined : product.discounted_price,
            };

            if (product.price !== product.discounted_price) {
                commerce['discountPrice'] = product.discounted_price;
            }

            try {
                Kakao.Share.sendDefault({
                    objectType: 'commerce',
                    content: {
                        title: product.comments[0] || product.name,
                        imageUrl: product.image_url,
                        link: {
                            mobileWebUrl: shareLink,
                            webUrl: shareLink,
                        },
                    },
                    commerce: commerce,
                    buttons: [
                        {
                            title: '바로가기',
                            link: {
                                mobileWebUrl: shareLink,
                                webUrl: shareLink,
                            },
                        },
                    ],
                });
            } catch (e) {
                Sentry.captureException(e);
            }
        }} shareLink={shareLink} offsetY={-60} offsetX={'-50%'}>
            <div className={styles.shareButton}>
                <FullButton title={text} height={30} fontSize={14} onClick={undefined} sidePadding={12}/>
            </div>
        </ShareButton>
    )
};


const ProductShareModal = (props) => {
    const {t} = useTranslation('component-productShare-modal');
    const [isLoading, setIsLoading] = useState(false);
    const {product, handleClose, onSuccess} = props;

    const handleModalClose = () => {
        handleClose();
    };

    const {user, commissionRate} = useContext(UserContext);

    return (
        <Modal showOverflow={false} isOpen={true} bottom={true} width="100%" maxHeight={600}
               close={handleModalClose}
        >
            <div className={styles.root}>
                <div className={styles.content}>
                    <div className={styles.descriptionContainer}>
                        <span className={styles.subtitle}>{t('title')}</span>
                        <span className={styles.description}>{t('description', {commissionRate: commissionRate})}</span>
                    </div>
                    {
                        user !== undefined &&
                        <WebProductShareModalButton product={product} isLoading={isLoading} setIsLoading={setIsLoading}
                                                    onSuccess={onSuccess}/>
                    }
                </div>
                <div className={styles.content}>
                    <div className={styles.descriptionContainer}>
                        <span className={styles.subtitle}>{t('nonRewardTitle')}</span>
                    </div>
                    {
                        user !== undefined &&
                        <WebProductShareModalButton product={product} isLoading={isLoading} setIsLoading={setIsLoading}
                                                    withoutReward/>
                    }
                </div>
            </div>
        </Modal>
    )
};

export default ProductShareModal;
