import styles from 'components/modal/CouponModal/AppPointModal.module.scss';
import {useContext, useEffect, useState} from "react";
import PushContext from "context/PushContext";
import {customConfirm, numberWithComma} from "common/utils";
import ModalContext from "context/ModalContext";
import {toast} from "react-toastify";
import {useRouter} from "next/router";
import classNames from "classnames";
import BulletText from "components/BulletText";
import CloseIcon from "images/icons/close_new.svg";
import {hideForXMinutes} from "common/modalHelper";
import {mainCouponModalKey} from "components/modal/MainCouponModal";
import {registerHmkCid} from "common/hmkcid";


const AppPointModal = (props) => {
    const {modalInfo, setIsPushButtonPressed, downloadCoupon, pseudoState} = props;
    const close = props.close || function () {};

    const router = useRouter();

    const pushContext = useContext(PushContext);

    const {receivedCouponMoldIdsRef} = useContext(ModalContext);

    const [inputPath, setInputPath] = useState(null);


    const appCouponMold = modalInfo.coupon_molds.filter(e => e.mold_key.includes('app_install'))[0];
    const pushAgreeCouponMold = modalInfo.coupon_molds.filter(e => e.mold_key.includes('push_allow'))[0];
    const referralCouponMold = modalInfo.coupon_molds.filter(e => e.mold_key.includes('hmkcid_referral'))[0];

    const appCouponReceived = appCouponMold ? receivedCouponMoldIdsRef.current.includes(appCouponMold.id) : false;
    const pushCouponReceived = pushAgreeCouponMold ? receivedCouponMoldIdsRef.current.includes(pushAgreeCouponMold.id) : false;
    const referralCouponReceived = referralCouponMold ? receivedCouponMoldIdsRef.current.includes(referralCouponMold.id) : false;

    const appCouponText = appCouponMold ?
        (
            appCouponMold.extra_points > 0 ?
                `${numberWithComma(appCouponMold.extra_points)}P + ${appCouponMold.amount}% 쿠폰 받기` :
                `${appCouponMold.amount}% 쿠폰 받기`
        ) :
        null;

    const pushCouponText = pushAgreeCouponMold ?
        (
            pushAgreeCouponMold.extra_points > 0 ?
                `${numberWithComma(pushAgreeCouponMold.extra_points)}P + ${pushAgreeCouponMold.amount}% 쿠폰 받기` :
                `${pushAgreeCouponMold.amount}% 쿠폰 받기`
        ) :
        null;

    const referralCouponText = referralCouponMold ?
        (
            `붙여넣고 ${(referralCouponMold.extra_points || 0)}P 받기`
        ):
        null;

    useEffect(() => {
        if (!modalInfo) return;
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'AppPointModalOpen',
            {
                withReferral: !!referralCouponMold,
            }
        );
    }, [modalInfo]);

    const [referralInputText, setReferralInputText] = useState('');

    const onInputChangeHandler = (e) => {
        setReferralInputText(e.target.value);
    };

    const receiveAppCoupon = () => {
        if (!Boolean(appCouponMold)) {
            return;
        }
        if (appCouponReceived) {
            toast.info('이미 다운받은 쿠폰이에요.');
            return;
        }
        typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
            mold_key: appCouponMold.mold_key,
            identifier: modalInfo.identifier,
            pathname: router.pathname,
        });
        downloadCoupon(appCouponMold);
    };

    const receiveReferralCoupon = () => {
        if (!Boolean(referralCouponMold)) {
            return;
        }

        if (referralCouponReceived) {
            toast.info('이미 포인트를 받았어요.');
            setReferralInputText('')
            return;
        }

        if (!referralInputText.trim()) {
            toast.info('시크릿 코드를 붙여넣어 주세요.');
            setReferralInputText('')
            return;
        }

        let decodedInputText = '';
        try {
            decodedInputText = atob(referralInputText.trim());
        } catch {
            toast.info('시크릿 코드를 정확히 붙여넣어 주세요.');
            return;
        }

        if (!decodedInputText.includes('hemekolab://webview')) {
            toast.info('시크릿 코드를 정확히 붙여넣어 주세요.');
            return;
        }

        let isBrandPage = false;
        if (typeof window !== 'undefined') {
            isBrandPage = window?.location?.pathname?.endsWith('/store/brands');
        }

        const searchParams = new URLSearchParams(decodedInputText.split('?')[1]);
        const hmkcid = searchParams.get('hmkcid') || (isBrandPage ? null : searchParams.get('c'));
        if (hmkcid) {
            registerHmkCid(hmkcid);
        }

        typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
            mold_key: referralCouponMold.mold_key,
            identifier: modalInfo.identifier,
            pathname: router.pathname,
        });

        setInputPath(decodedInputText.replace('hemekolab://webview', ''));
        downloadCoupon(referralCouponMold);
    };

    const pushAgree = () => {
        if (!Boolean(pushAgreeCouponMold)) {
            return;
        }
        if (pushCouponReceived) {
            toast.info('이미 다운받은 쿠폰이에요.');
            return;
        }

        typeof mixpanel !== 'undefined' && mixpanel.track('MainCouponModalCouponDownload', {
            mold_key: pushAgreeCouponMold.mold_key,
            identifier: modalInfo.identifier,
            pathname: router.pathname,
        });
        setIsPushButtonPressed(true);
        pushContext.sendPushAgreementRequest(true);
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'PushAgreementModalButtonClicked',
            {
                identifier: modalInfo.identifier,
            }
        );
    };

    const checkInputPath = () => {
        if (inputPath && (inputPath !== '/' && inputPath !== '/home')) {
            customConfirm('웹에서 보시던 헤메코 페이지가 있으셨던 것 같아요! 이동시켜 드릴까요?', '확인', '취소', () => {
                typeof mixpanel !== 'undefined' && mixpanel.track('AppPointModalGoToInputPath');
                router.push(inputPath);
                close();
            }, () => {
                close();
            });
            return;
        }
        close();
    };

    const handleCloseButtonClick = () => {
        const isFinished = (
            !referralCouponMold ?
                (!!appCouponReceived && !!pushCouponReceived)
                :
                (!!appCouponReceived && !!pushCouponReceived && !!referralCouponReceived)
        );

        typeof mixpanel !== 'undefined' && mixpanel.track('AppPointModalCloseButtonClick', {
            isFinished: isFinished,
        });

        if (!isFinished) {
            customConfirm('창을 닫으면 다시 혜택을 받을 수 없어요.\n정말 닫으시겠어요?', '확인', '취소', () => {
                typeof mixpanel !== 'undefined' && mixpanel.track('AppPointModalCloseButtonConfirm');
                hideForXMinutes(mainCouponModalKey(modalInfo.id), 6000000);
                close();
            }, () => {
                typeof mixpanel !== 'undefined' && mixpanel.track('AppPointModalCloseButtonCancel');
            });
        } else {
            close();
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.subContainer}>
                <div className={styles.closeIcon} onClick={handleCloseButtonClick}>
                    <CloseIcon viewBox="0 0 17 17" width={24} height={24} />
                </div>
                <div className={styles.title}>
                    앱으로 오신 걸 환영해요🙋🏻‍<br/>
                    1,000P와 쿠폰 받아가세요
                </div>
                <div className={styles.couponIcon} />
                <div className={styles.contentContainer}>
                    <div className={styles.normalBox}>
                        <div className={styles.normalBoxLeftText}>
                            헤메코랩 설치하고
                        </div>
                        <div onClick={receiveAppCoupon} className={classNames(styles.smallReceiveButton, appCouponReceived ? styles.receiveButtonInactive : undefined)}>
                            {appCouponText}
                        </div>
                    </div>
                    <div className={styles.normalBox}>
                        <div className={styles.normalBoxLeftText}>
                            푸시알림 받고
                        </div>
                        <div onClick={pushAgree} className={classNames(styles.smallReceiveButton, pseudoState !== undefined && !!pushCouponReceived ? styles.receiveButtonInactive : undefined)}>
                            {pushCouponText}
                        </div>
                    </div>
                    {
                        !!referralCouponMold &&
                        <div className={styles.bigBox}>
                            <div className={styles.bigBoxTitle}>다른 브라우저에서 오셨나요?</div>
                            <div className={styles.bigBoxText}>
                                <span>보던 헤메코랩 웹사이트 상단</span><div className={styles.bigIcon}>앱에서 보기</div><span>를 누르면</span>
                            </div>
                            <div className={styles.bigBoxText}>시크릿 코드를 복사할 수 있어요</div>
                            <div className={styles.bigBoxButtonWrapper}>
                                <div className={styles.bigBoxInputWrapper}>
                                    <input onChange={onInputChangeHandler} value={referralInputText} type="text" className={styles.bigBoxInput} placeholder={'시크릿 코드'} />
                                </div>
                                <div onClick={receiveReferralCoupon} className={classNames(styles.smallReceiveButton, referralCouponReceived ? styles.receiveButtonInactive : undefined)}>
                                    {referralCouponText}
                                </div>
                            </div>
                        </div>
                    }
                    <div className={styles.bulletContainer}>
                        <BulletText fontColor={'#9e9e9e'} fontSize={12} lineHeight={'140%'}>해당 이벤트는 공지 없이 종료될 수 있어요</BulletText>
                        <BulletText fontColor={'#9e9e9e'} fontSize={12} lineHeight={'140%'}>쿠폰 만료 직전에 알림톡이 전송될 수 있어요</BulletText>
                    </div>
                </div>
            </div>
        </div>
        // <div className={styles.root}>
        //     <div className={styles.content}>
        //         <div className={styles.title}>앱 전용 혜택</div>
        //         <div className={styles.description}>첫 구매에도 사용 가능한 포인트 받으세요🥹</div>
        //         <img
        //             className={styles.image}
        //             src={'https://d1cnx04b8cgzcv.cloudfront.net/media/modal/app_install_modal_point_icon.png'}
        //             alt="할인 이미지"
        //         />
        //         <div className={styles.notice}>
        //             <div className={styles.noticeText}>• 해당 이벤트는 공지 없이 종료될 수 있어요</div>
        //             <div className={styles.noticeText}>• 쿠폰 만료 직전에 알림톡을 보내드려요</div>
        //         </div>
        //         <div className={styles.couponContainer}>
        //             {
        //                 appCouponText &&
        //                 <div className={styles.couponWrapper}>
        //                     <div className={styles.couponTitle}>헤메코랩 설치하기</div>
        //                     <div className={styles.button}>
        //                         <FullButton
        //                             height={36} fontSize={12} title={appCouponText}
        //                             onClick={receiveAppCoupon} light={appCouponReceived}
        //                         />
        //                     </div>
        //                 </div>
        //             }
        //             {
        //                 pushCouponText &&
        //                 <div className={styles.couponWrapper}>
        //                     <div className={styles.couponTitle}>푸시알림 받기</div>
        //                     <div className={styles.button}>
        //                         <FullButton
        //                             height={36} fontSize={12} title={pushCouponText}
        //                             onClick={pushAgree} light={pushCouponReceived}
        //                         />
        //                     </div>
        //                 </div>
        //             }
        //         </div>
        //     </div>
        // </div>
    )
};


export default AppPointModal;
