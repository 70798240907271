import {sellerProductContextRef} from "context/SellerProductContext";
import {Axios} from "api/index";
import {toast} from "react-toastify";
import * as Sentry from "@sentry/nextjs";


export const addToSellerShop = async (productId, isAdded, t, showResultToast = true) => {
    const data = {'product_id': productId, 'is_added': isAdded};
    let success = false;
    try {
        sellerProductContextRef.current.setIsLoading(productId, true);
        const res = isAdded
            ? await Axios.post(`v1/my-shop/products/`, data)
            : await Axios.delete(`v1/my-shop/products/`, {params: data});
        if (res.status < 400) {
            if (isAdded) {
                sellerProductContextRef.current.addProductIds([productId]);
                if (showResultToast) {
                    toast.info(t('CreatorPage.addProductToast'));
                }
            } else {
                sellerProductContextRef.current.removeProductId(productId);
                if (showResultToast) {
                    toast.info(t("CreatorPage.removeProductToast"));
                }
            }
            success = true;
        } else {
            toast.info(res.data.display_message || t("CreatorPage.temporaryError"));
        }
    } catch (e) {
        toast.info(t("CreatorPage.temporaryError"));
        Sentry.captureException(e);
    } finally {
        sellerProductContextRef.current.setIsLoading(productId, false);
    }
    return success;
};
