import styles from "pages/store/orders/[ordNum]/checkout.module.scss";
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import classNames from "classnames";
import utilStyles from "styles/utils.module.scss";
import ArrowRightIcon from "images/icons/arrow_right.svg";
import CheckoutCouponCard from "components/cards/CheckoutCouponCard";
import Modal from "components/Modal";
import TitleBar from "components/layout/TitleBar";
import FullButton from "components/buttons/FullButton";
import TextInput from "components/input/TextInput";
import {formattedPrice, isApp, numberWithComma} from "common/utils";
import CheckoutPointCard from "components/cards/CheckoutPointCard";
import {
    KEY_ADDRESS_COUNTRY,
    KEY_SELECTED_COUPONS,
    KEY_SUGGEST_BEST_COUPON,
    KEY_USED_POINTS
} from "pages/store/orders/[ordNum]/checkout";
import {useContext, useMemo, useState} from "react";
import {Axios} from "api";
import {captureException} from "@sentry/nextjs";
import AppIcon from "images/icons/app_icon.svg";
import CloseIcon from "images/icons/close.svg";
import PushContext from "context/PushContext";
import {redirectToApp} from "common/redirect";
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import {useRouter} from "next/router";
import {toastContextRef} from "context/ToastContext";
import DownloadableCouponCardV2 from "components/cards/coupon/DownloadableCouponCardV2";
import {PromotionAndCouponCard} from "components/cards/coupon/PromotionAndCouponCard";


const CheckoutCouponInfoSection = (props) => {
    const { t } = useTranslation('sections-checkout-CheckoutCouponInfoSection');
    const {isLoading, setIsLoading, order, formik, initialCoupons, selectedCoupons, setOrder, isPaypalLoaded, user} = props;

    const {values, setFieldValue, setValues, handleSubmit, errors, touched} = formik;

    const pushContext = useContext(PushContext);
    const router = useRouter();

    const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
    const [mobileSelectedCoupons, setMobileSelectedCoupons] = useState(initialCoupons);
    const [isAppModalOpen, setIsAppModalOpen] = useState(false);
    const [isPushModalOpen, setIsPushModalOpen] = useState(false);

    const updateCoupons = async (coupons, _onSuccess) => {
        const onSuccess = _onSuccess || function () {
        };
        setIsLoading(true);
        try {
            const res = await Axios.post(`v1/store/orders/${order.order.order_number}/cost`, {
                [KEY_SELECTED_COUPONS]: coupons,
                [KEY_SUGGEST_BEST_COUPON]: false,
                [KEY_ADDRESS_COUNTRY]: values[KEY_ADDRESS_COUNTRY]
            });
            if (res.status < 400) {
                setOrder(res.data);
                setFieldValue(KEY_SELECTED_COUPONS, res.data[KEY_SELECTED_COUPONS]);
                onSuccess();
            }
        } catch (e) {
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const setCoupons = async (coupons, onSuccess) => {
        await updateCoupons(coupons, onSuccess);
    };

    const submitPromotionCode = async (coupon, _onSuccess) => {
        const onSuccess = _onSuccess || function () {
        };
        setIsLoading(true);
        try {
            const res = await Axios.post(`v1/store/orders/${order.order.order_number}/cost`, {
                [KEY_ADDRESS_COUNTRY]: values[KEY_ADDRESS_COUNTRY],
                [KEY_SELECTED_COUPONS]: [coupon],
                [KEY_SUGGEST_BEST_COUPON]: false,
            });
            if (res.status < 400) {
                setOrder(res.data);
                setFieldValue(KEY_SELECTED_COUPONS, res.data[KEY_SELECTED_COUPONS]);
                handleCouponSelection(coupon);
                onSuccess(coupon);
            }
        } catch (e) {
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }

    const selectCouponTextClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageSelectCouponTextClick');
        if (isLoading || isPaypalLoaded || !Boolean(order?.available_coupons?.length > 0)  || selectedCoupons?.length > 0) return;
        setMobileSelectedCoupons(values[KEY_SELECTED_COUPONS]);
        setIsCouponModalOpen(true);
    };

    const selectCouponInputBoxClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageSelectCouponInputBoxClick', {hasCoupon: order.available_coupons.length > 0});
        setMobileSelectedCoupons(values[KEY_SELECTED_COUPONS]);
        setIsCouponModalOpen(true);
    };

    const onSubmitCoupon = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageCouponModalSubmitClick', {selectedCoupons: mobileSelectedCoupons});
        if (mobileSelectedCoupons === values[KEY_SELECTED_COUPONS]) {
            setIsCouponModalOpen(false);
            return;
        }
        setCoupons(mobileSelectedCoupons, () => setIsCouponModalOpen(false));
    };

    const handleDownloadSuccess = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track('CheckoutPageCouponModalDownloadCoupon', {});
        toastContextRef.current?.info(t('CheckoutCouponInfoSection.downloadCouponSuccessToastMessage'));
        setCoupons(mobileSelectedCoupons, () => {});
    }

    const couponIdToCoupon = useMemo(() => {
        return order.available_coupons.reduce((acc, coupon) => {
            acc[coupon.id] = coupon;
            return acc;
        }, {});
    }, [order]);

    const handleCouponSelection = (coupon) => {
        const couponId = coupon.id;
        if (!isApp() && coupon.is_app_only) {
            setIsAppModalOpen(true);
            return;
        }

        if (coupon.is_push_only && !isPushOn) {
            setIsPushModalOpen(true);
            return;
        }

        if (coupon.is_double_discount) {
            setMobileSelectedCoupons(prev => {
                const coupons = prev || [];

                if (coupons.filter(e => e.id === couponId).length > 0) {
                    return coupons.filter(e => e.id !== couponId);
                } else {
                    const remainingCoupons = [];
                    for (let i = 0; i < coupons.length; i++) {
                        const prevCouponId = coupons[i].id;
                        const prevCoupon = couponIdToCoupon[prevCouponId];
                        if (!Boolean(prevCoupon?.is_double_discount)) {
                            remainingCoupons.push(prevCoupon);
                        } else {
                            const remainingPrevCouponAppliedOptionIds = prevCoupon.applied_option_ids.filter(
                                optionId => !coupon.applied_option_ids.includes(optionId)
                            );
                            if (remainingPrevCouponAppliedOptionIds.length > 0) {
                                remainingCoupons.push(prevCoupon);
                            }
                        }
                    }

                    return [...remainingCoupons, coupon].filter(e => Boolean(e?.id)).map(e => ({id: e.id, option_id: null}));
                }
            });
        } else {
            setMobileSelectedCoupons(prev => {
                const coupons = prev || [];
                if (coupons.filter(e => e.id === couponId).length > 0) {
                    return coupons.filter(e => e.id !== couponId);
                } else {
                    const remainingCoupons = [];
                    for (let i = 0; i < coupons.length; i++) {
                        const prevCouponId = coupons[i].id;
                        const prevCoupon = couponIdToCoupon[prevCouponId];
                        if (Boolean(prevCoupon?.is_double_discount)) {
                            remainingCoupons.push(prevCoupon);
                        }
                    }
                    return [...remainingCoupons, coupon].filter(e => Boolean(e?.id)).map(e => ({id: e.id, option_id: null}));
                }
            });
        }
    };

    const availableInitialDiscountCoupons = order?.available_coupons?.filter(e => !e.is_double_discount) || [];
    const unavailableInitialDiscountCoupons = order?.unavailable_coupons?.filter(e => !e.is_double_discount) || [];
    const availableDoubleDiscountCoupons = order?.available_coupons?.filter(e => e.is_double_discount) || [];
    const availableDoubleDiscountCouponIds = availableDoubleDiscountCoupons.map(e => e.id);
    const unavailableDoubleDiscountCoupons = order?.unavailable_coupons?.filter(e => e.is_double_discount) || [];
    const downloadableDoubleDiscountCoupons = order?.downloadable_coupon_molds?.filter(
        e => e.is_double_discount && !availableDoubleDiscountCouponIds.includes(e.id)
    ) || [];

    const selectedCouponName = useMemo(() => {
        if (!Boolean(selectedCoupons) || selectedCoupons.length === 0) {
            return '';
        }
        if (selectedCoupons.length === 1) {
            return selectedCoupons[0].name;
        }
        const mainCoupon = selectedCoupons.find(e => !e.is_double_discount) || selectedCoupons[0];
        // TODO: i18n
        return `${mainCoupon.name} 외 ${selectedCoupons.length - 1}개`;
    }, [selectedCoupons]);

    const isVisibleDoubleDiscountCouponSection = useMemo(() => {
        const availableDoubleDiscountCoupons = order?.available_coupons?.filter(e => e.is_double_discount) || [];

        return availableDoubleDiscountCoupons?.length > 0 || downloadableDoubleDiscountCoupons?.length > 0;
    }, [order])


    const isPushOn = pushContext.isDevicePushOn && pushContext.isMarketingAgreed;

    const removeCoupon = (coupon) => {
        updateCoupons(values[KEY_SELECTED_COUPONS].filter(e => e.id !== coupon.id));
    }

    return (
        <div className={styles.section}>
            <Modal isOpen={isCouponModalOpen} height={'100%'} width={'100%'}
                   close={() => setIsCouponModalOpen(false)}>
                <div className={styles.modalContainer}>
                    <TitleBar title={t('CheckoutCouponInfoSection.discountCouponTitle')}
                              close={() => setIsCouponModalOpen(false)} isClose={true}/>
                    <div className={isVisibleDoubleDiscountCouponSection ? styles.couponsContainer : styles.lastCouponsContainer}>
                        <div className={styles.couponModalSubtitle}>
                            {t('CheckoutCouponInfoSection.pouchCouponTitle')}
                        </div>
                        {availableInitialDiscountCoupons.map(e =>
                            <CheckoutCouponCard
                                showTotal relative withBorder key={e.id} coupon={e} isLoading={isLoading}
                                onSelect={handleCouponSelection}
                                selectedCoupons={mobileSelectedCoupons}
                            />
                        )}
                        {
                            unavailableInitialDiscountCoupons &&
                            unavailableInitialDiscountCoupons.map(e => {
                                return <CheckoutCouponCard
                                    relative expired
                                    withBorder key={`unavailableInitialDiscountCoupons-${e.id}`} coupon={e} isLoading={isLoading} onSelect={() => {
                                }} selectedCoupons={[]}
                                />
                            })
                        }
                    </div>
                    {
                        isVisibleDoubleDiscountCouponSection &&
                        <div className={classNames(styles.lastCouponsContainer)} style={{overflow: 'auto'}}>
                            <div className={styles.couponModalSubtitle}>
                                {t('CheckoutCouponInfoSection.additionalCouponTitle')}
                            </div>
                            {
                                availableDoubleDiscountCoupons.map(e =>
                                    <CheckoutCouponCard
                                        showTotal relative withBorder
                                        key={e.id} coupon={e} isLoading={isLoading}
                                        onSelect={handleCouponSelection}
                                        selectedCoupons={mobileSelectedCoupons}
                                    />
                                )}
                            {
                                unavailableDoubleDiscountCoupons.map(e =>
                                    <CheckoutCouponCard
                                        showTotal relative withBorder key={e.id} coupon={e} isLoading={isLoading}
                                        onSelect={() => {
                                        }} expired
                                        selectedCoupons={mobileSelectedCoupons}
                                    />
                            )}
                            {
                                downloadableDoubleDiscountCoupons.map(e =>
                                <div className={styles.downloadableCouponContainer}>
                                    <DownloadableCouponCardV2
                                        showTotal relative withBorder
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        key={e.id} couponMold={e}
                                        onDownloadSuccess={handleDownloadSuccess}
                                        user={user} expired
                                        router={router}
                                    />
                                </div>
                            )}
                        </div>
                    }
                    <div className={styles.couponModalButtonContainer}>
                        <FullButton title={t('CheckoutCouponInfoSection.apply')} height={48} fontSize={16}
                                    onClick={onSubmitCoupon}/>
                    </div>
                </div>
            </Modal>
            <Modal isOpen={isAppModalOpen} bottom close={() => {
                setIsAppModalOpen(false)
            }} width={'100%'}>
                <div className={styles.appModalContainer}>
                    <div className={styles.appModalCloseButton} onClick={() => setIsAppModalOpen(false)}>
                        <CloseIcon width="24" height="24" viewBox="0 0 20 20"/>
                    </div>
                    <div className={styles.appModalContentWrapper}>
                        <AppIcon width="60" height="60" viewBox="0 0 60 60"/>
                        <span className={styles.appModalContent}>
                        <Trans
                            i18nKey="sections-checkout-CheckoutCouponInfoSection:CheckoutCouponInfoSection.appOnlyCouponMessage"
                            components={[<br key="br"/>]}
                        />
                    </span>
                    </div>
                    <FullButton
                        onClick={() => {
                            redirectToApp();
                        }}
                        height={50} fontSize={16}>{t('CheckoutCouponInfoSection.installApp')}</FullButton>
                </div>
            </Modal>
            <Modal isOpen={isPushModalOpen} bottom close={() => {
                setIsPushModalOpen(false)
            }} width={'100%'}>
                <div className={styles.appModalContainer}>
                    <div className={styles.appModalCloseButton} onClick={() => setIsPushModalOpen(false)}>
                        <CloseIcon width="24" height="24" viewBox="0 0 20 20"/>
                    </div>
                    <div className={styles.appModalContentWrapper}>
                        <AppIcon width="60" height="60" viewBox="0 0 60 60"/>
                        <span className={styles.appModalContent}>
                        {t('CheckoutCouponInfoSection.pushAgreementMessage')}
                    </span>
                    </div>
                    <FullButton
                        onClick={() => {
                            pushContext.sendPushAgreementRequest(true);
                            setIsPushModalOpen(false);
                        }}
                        height={50} fontSize={16}>{t('CheckoutCouponInfoSection.agreePushNotification')}</FullButton>
                </div>
            </Modal>
            <span className={styles.title}>{t('CheckoutCouponInfoSection.discountApplication')}</span>
            <div className={classNames(utilStyles.flexRow, styles.subTitle, utilStyles.justifyContentSpaceBetween)}>
                <span>{t('CheckoutCouponInfoSection.discountTitle')}</span>
                <div className={utilStyles.cursorPointer} onClick={selectCouponTextClick}>
                    <span
                        className={isPaypalLoaded || !Boolean(order?.available_coupons?.length > 0) || Boolean(selectedCoupons?.length > 0) ? checkoutStyles.rightActionDisabled : checkoutStyles.rightAction}>
                        {t('CheckoutCouponInfoSection.select')}
                    </span>
                    <ArrowRightIcon viewBox="0 0 16 16"
                                    className={isPaypalLoaded || !Boolean(order?.available_coupons?.length > 0) || Boolean(selectedCoupons?.length > 0) ? checkoutStyles.rightActionArrowDisabled : checkoutStyles.rightActionArrow}/>
                </div>
            </div>
            <PromotionAndCouponCard
                order={order} selectedCoupons={selectedCoupons} removeCoupon={removeCoupon}
                onSubmit={submitPromotionCode} availableDoubleDiscountCoupons={availableInitialDiscountCoupons}
            />
            <div style={{height: 20}}/>
            <div className={styles.subTitle}>{t('CheckoutCouponInfoSection.points')} <span
                className={styles.parenthesis}>{t('CheckoutCouponInfoSection.pointsAvailable', {0: numberWithComma(order.available_points)})}</span>
            </div>
            <CheckoutPointCard
                maxUsablePoints={order.max_usable_points} minUsablePoints={order.min_usable_points}
                isReservation={order.order?.is_reservation}
                value={values[KEY_USED_POINTS]} onChange={e => setFieldValue(KEY_USED_POINTS, e)}
                currency={order.order?.currency} disable={isPaypalLoaded}
                availablePoints={order?.available_points}
            />
        </div>
    )
};

export default CheckoutCouponInfoSection;
