import styles from "components/cards/checkoutAddressCardGlobal.module.scss";
import utilStyles from 'styles/utils.module.scss';
import checkoutStyles from "pages/store/orders/[ordNum]/checkout.module.scss";
import classNames from "classnames";
import PipeIcon from "images/icons/pipe.svg";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import Modal from "components/Modal";
import AddressList, {
    GLOBAL_PHONE_NUMBER_REGEX,
    NUMBER_REGEX,
    PHONE_NUMBER_REGEX
} from "components/modal/DeliveryAddressModal";
import TextInput from "components/input/TextInput";
import {
    deliveryMemoChoices,
    KEY_ADDRESS_CITY,
    KEY_ADDRESS_COUNTRY,
    KEY_ADDRESS_ID,
    KEY_ADDRESS_LINE_1,
    KEY_ADDRESS_LINE_2,
    KEY_ADDRESS_STATE,
    KEY_ADDRESS_ZIP_CODE, KEY_AGREEMENT_CHECKED,
    KEY_DELIVERY_MEMO,
    KEY_OVERSEAS_EMAIL,
    KEY_OVERSEAS_FIRST_NAME,
    KEY_OVERSEAS_LAST_NAME,
    KEY_OVERSEAS_PHONE_COUNTRY_CODE,
    KEY_OVERSEAS_PHONE_NUMBER
} from "pages/store/orders/[ordNum]/checkout";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {findNextInputAndFocus, getLocalStorageCountryCode, isValidPhoneNumber} from "common/utils";
import InputError from "components/input/InputError";
import {defaultCacheOption} from "api";
import {captureException} from "@sentry/nextjs";
import {toast} from "react-toastify";
import axios from "axios";
import {COUNTRY_CODE_TO_COUNTRY_INFO} from "common/country_list";
import CountryInputSelector from "components/input/CountryInputSelector";
import {KOREA_COUNTRY_CODE, US_COUNTRY_CODE} from "common/i18n";
import DeliveryContext from "context/DeliveryContext";

export async function onZipcodeApply(countryCode, zipCode) {
    try {
        if(zipCode === '' || zipCode === undefined) {
            return
        }

        const url = "https://api.zippopotam.us/"+ countryCode +"/" + zipCode;
        const res = await axios.get(url, {
            cache: defaultCacheOption,
        })

        if (res.status < 400) {
            return res.data['places']?.[0];
        }
        else {
            toast.info( 'Cannot find the address. Please check the zip code again.');
        }
    } catch (e) {
        toast.info( 'Cannot find the address. Please check the zip code again.');
        captureException(e);
    } finally {
    }
}

export const DeliveryInstructionComponent = ({countryCode}) => {
    const {fetchInstructions} = useContext(DeliveryContext);
    const [deliveryInstructions, setDeliveryInstructions] = useState([]);

    const getHighlightText = (text) => {
        const regex = /{(.*?)}/g;
        const parts = [];

        let lastIndex = 0;
        let match;

        while ((match = regex.exec(text)) !== null) {
            if (match.index > lastIndex) {
                parts.push(text.slice(lastIndex, match.index));
            }
            parts.push(<span className={styles.guideCommentHighlightStyle} key={match.index}>{match[1]}</span>);
            lastIndex = regex.lastIndex;
        }

        if (lastIndex < text.length) {
            parts.push(text.slice(lastIndex));
        }

        return <span className={styles.guideCommentStyle}>{parts}</span>;
    };

    useEffect(() => {
        fetchInstructions(countryCode).then((data) => {
            setDeliveryInstructions(data);
        });
    }, [countryCode]);

    return (
        deliveryInstructions?.length > 0 &&
        <div className={styles.shippingGuideContainer}>
            <div className={styles.shippingGuideTitle}>
                Shipping Guide
            </div>
            {
                deliveryInstructions.map((text, index) => {
                    return (
                        <div key={`instruction-container-${index}`} className={styles.guideCommentContainer}>
                            <div className={styles.guideCommentDot}>
                                <span key={`dot-${index}`}>{`•`}</span>
                            </div>
                            {
                                getHighlightText(text)
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default function CheckoutAddressCardGlobal(props) {
    const {
        values,
        user,
        setFieldValue,
        isLoading,
        errors,
        touched,
        nameInputRef,
        setValues,
        isAddressListModalOpen,
        setIsAddressListModalOpen,
        instructions,
        disableChangeCountryCode,
    } = props;

    const {fetchDeliveryStrategy} = useContext(DeliveryContext)
    const onChangeAddress = props.onChangeAddress || function () {
    };
    const isMobile = useMediaQuery(`(max-width:${utilStyles.breakpointMobile})`);

    const countryCode = values[KEY_ADDRESS_COUNTRY]?? getLocalStorageCountryCode()?? 'KR'
    const phoneNumberCountryCodeInputRef = useRef(null);
    const phoneNumberInputRef = useRef(null);
    const [showAddressDetail, setShowAddressDetail] = useState(!Boolean(values[KEY_ADDRESS_ID]));

    const onCountryChoiceClick = (e) => {
        setFieldValue(KEY_ADDRESS_COUNTRY, COUNTRY_CODE_TO_COUNTRY_INFO[e]?.countryCode);
        setFieldValue(KEY_OVERSEAS_PHONE_COUNTRY_CODE, COUNTRY_CODE_TO_COUNTRY_INFO[e]?.phoneCountryCode)
    };

    const applyButtonClick = async () => {
        if (countryCode === KOREA_COUNTRY_CODE || disableChangeCountryCode) {
            return;
        }

        setFieldValue(KEY_ADDRESS_CITY, '');
        setFieldValue(KEY_ADDRESS_STATE, '');

        const data = await onZipcodeApply(countryCode, values[KEY_ADDRESS_ZIP_CODE]);
        setFieldValue(KEY_ADDRESS_CITY, data?.['place name']);
        setFieldValue(KEY_ADDRESS_STATE, data?.['state abbreviation']);
    }

    const stateTitle = values[KEY_ADDRESS_COUNTRY] !== 'US' ? 'State' : 'State Code';
    const statePlaceHolder = values[KEY_ADDRESS_COUNTRY] !== 'US' ? 'Enter your state' : 'Enter your state code (ex: NY)';

    useEffect(() => {
        if(!values[KEY_ADDRESS_ID] || showAddressDetail) {
            return true;
        }

        const errorKeys = Object.keys(errors);

        for (let i = 0; i < errorKeys.length; i++) {
            if (errorKeys[i] !== KEY_AGREEMENT_CHECKED && touched[errorKeys[i]]) {
                setShowAddressDetail(true);
            }
        }
    }, [values]);

    return (
        <div>
            {
                isAddressListModalOpen && !!user &&
                <Modal width={isMobile ? '100%' : 416} height={isMobile ? '100%' : undefined}
                       isOpen={isAddressListModalOpen}
                       close={() => setIsAddressListModalOpen(false)}>
                    {
                        <AddressList
                            defaultScreen={null}
                            onChangeAddress={(e) => {
                                onChangeAddress(e);
                            }}
                            close={() => setIsAddressListModalOpen(false)}/>
                    }
                </Modal>
            }
            {
                showAddressDetail ?
                    <div style={{marginBottom: 8}}>
                        <div style={{marginBottom: 20}}>
                            <div className={styles.headLineContainer}>
                                <span>Shipping Country</span>
                            </div>
                            <div className={classNames(checkoutStyles.inputWrapper, styles.elementContainer)}>
                                <CountryInputSelector
                                    placeholder="Select your shipping location"
                                    onlyPossibleCountries
                                    onChange={onCountryChoiceClick}
                                    countryCode={values[KEY_ADDRESS_COUNTRY]}
                                    disable={disableChangeCountryCode}
                                />
                            </div>
                            <DeliveryInstructionComponent countryCode={values[KEY_ADDRESS_COUNTRY]}/>
                        </div>
                        <div className={styles.headLineContainer}>
                            <span>Shipping Address</span>
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <div className={classNames(checkoutStyles.inputContainer, styles.elementContainer)}>
                                <span className={checkoutStyles.inputLabel}>First Name</span>
                                <div className={checkoutStyles.inputWrapper}>
                                    <TextInput
                                        ref={nameInputRef}
                                        onEnter={e => findNextInputAndFocus(e.target)}
                                        error={errors[KEY_OVERSEAS_FIRST_NAME] && touched[KEY_OVERSEAS_FIRST_NAME]}
                                        placeholder="Enter your first name"
                                        readonly={isLoading}
                                        value={values[KEY_OVERSEAS_FIRST_NAME]}
                                        onChange={(e) => setFieldValue(KEY_OVERSEAS_FIRST_NAME, e)}
                                    />
                            </div>
                            {
                                (errors[KEY_OVERSEAS_FIRST_NAME] && touched[KEY_OVERSEAS_FIRST_NAME]) &&
                                <InputError message={errors[KEY_OVERSEAS_FIRST_NAME]}/>
                            }
                            </div>
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <div className={classNames(checkoutStyles.inputContainer, styles.elementContainer)}>
                                <span className={checkoutStyles.inputLabel}>Last Name</span>
                                <div className={checkoutStyles.inputWrapper}>
                                    <TextInput
                                        ref={nameInputRef}
                                        onEnter={e => findNextInputAndFocus(e.target)}
                                        error={errors[KEY_OVERSEAS_LAST_NAME] && touched[KEY_OVERSEAS_LAST_NAME]}
                                        placeholder="Enter your last name"
                                        readonly={isLoading}
                                        value={values[KEY_OVERSEAS_LAST_NAME]}
                                        onChange={(e) => setFieldValue(KEY_OVERSEAS_LAST_NAME, e)}

                                    />
                                </div>
                                {
                                    (errors[KEY_OVERSEAS_LAST_NAME] && touched[KEY_OVERSEAS_LAST_NAME]) &&
                                    <InputError message={errors[KEY_OVERSEAS_LAST_NAME]}/>
                                }
                            </div>
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <span className={checkoutStyles.inputLabel}>Phone Number</span>
                            <div className={checkoutStyles.inputWrapper}>
                                <div style={{maxWidth: 80}}>
                                    <TextInput
                                        pattern={'^[0-9]*'}
                                        type="text"
                                        ref={phoneNumberCountryCodeInputRef}
                                        error={errors[KEY_OVERSEAS_PHONE_COUNTRY_CODE] && touched[KEY_OVERSEAS_PHONE_COUNTRY_CODE]}
                                        placeholder={values[KEY_OVERSEAS_PHONE_COUNTRY_CODE]?? "Enter your phone country code"}
                                        value={values[KEY_OVERSEAS_PHONE_COUNTRY_CODE]}
                                        onChange={(e) => {
                                            if (e.startsWith('+')) {
                                                e = e.substring(1);
                                            }

                                            if (NUMBER_REGEX.test(e) || !e) {
                                                setFieldValue(KEY_OVERSEAS_PHONE_COUNTRY_CODE, '+' + e);
                                                if (e.length === 3) {
                                                    setTimeout(() => {
                                                        phoneNumberCountryCodeInputRef.current.blur();
                                                        setTimeout(() => {
                                                            findNextInputAndFocus(phoneNumberCountryCodeInputRef.current);
                                                        }, 100);
                                                    }, 100);
                                                }
                                            }
                                        }}
                                        onEnter={e => findNextInputAndFocus(e.target)}
                                        maxLength={4}
                                        readonly={isLoading}
                                    />
                                </div>
                                <TextInput
                                    type="text" readonly={isLoading} placeholder={'Enter your phone number'}
                                    pattern={'^[0-9-]+'}
                                    ref={phoneNumberInputRef}
                                    error={errors[KEY_OVERSEAS_PHONE_NUMBER] && touched[KEY_OVERSEAS_PHONE_NUMBER]}
                                    value={values[KEY_OVERSEAS_PHONE_NUMBER]}
                                    onChange={e => {
                                        if (GLOBAL_PHONE_NUMBER_REGEX.test(e)) {
                                            setFieldValue(KEY_OVERSEAS_PHONE_NUMBER, e);
                                        }
                                    }}/>
                            </div>
                            {
                                (errors[KEY_OVERSEAS_PHONE_NUMBER] && touched[KEY_OVERSEAS_PHONE_NUMBER]) &&
                                <InputError message={errors[KEY_OVERSEAS_PHONE_NUMBER]}/>
                            }
                            {
                                (errors[KEY_OVERSEAS_PHONE_COUNTRY_CODE] && touched[KEY_OVERSEAS_PHONE_COUNTRY_CODE]) &&
                                <InputError message={errors[KEY_OVERSEAS_PHONE_COUNTRY_CODE]}/>
                            }
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <span className={checkoutStyles.inputLabel}>Email</span>
                            <div className={checkoutStyles.inputWrapper}>
                                <TextInput
                                    type="email"
                                    onEnter={e => findNextInputAndFocus(e.target)}
                                    error={errors[KEY_OVERSEAS_EMAIL] && touched[KEY_OVERSEAS_EMAIL]}
                                    placeholder="Enter your email" value={values[KEY_OVERSEAS_EMAIL]}
                                    maxLength={64}
                                    readonly={isLoading}
                                    onChange={(e) => setFieldValue(KEY_OVERSEAS_EMAIL, e)}

                                />
                            </div>
                            {
                                (errors[KEY_OVERSEAS_EMAIL] && touched[KEY_OVERSEAS_EMAIL]) &&
                                <InputError message={errors[KEY_OVERSEAS_EMAIL]}/>
                            }
                        </div>
                        <div className={checkoutStyles.inputContainer}>
                            <span className={checkoutStyles.inputLabel}>Address</span>
                            <div className={checkoutStyles.inputWrapper}>
                                <TextInput
                                    readonly={isLoading}
                                    onEnter={e => findNextInputAndFocus(e.target)}
                                    error={errors[KEY_ADDRESS_LINE_1] && touched[KEY_ADDRESS_LINE_1]}
                                    placeholder="Enter your address line 1" value={values[KEY_ADDRESS_LINE_1]}
                                    maxLength={60}
                                    onChange={(e) => setFieldValue(KEY_ADDRESS_LINE_1, e)}

                                />
                            </div>
                            {
                                (errors[KEY_ADDRESS_LINE_1] && touched[KEY_ADDRESS_LINE_1]) &&
                                <InputError message={errors[KEY_ADDRESS_LINE_1]}/>
                            }
                            <div style={{marginTop: 8, width: '100%'}}>
                                <div className={checkoutStyles.inputWrapper}>
                                    <TextInput
                                        readonly={isLoading}
                                        onEnter={e => findNextInputAndFocus(e.target)}
                                        error={errors[KEY_ADDRESS_LINE_2] && touched[KEY_ADDRESS_LINE_2]}
                                        placeholder="Enter your address line 2 (Optional)" value={values[KEY_ADDRESS_LINE_2]}
                                        maxLength={60}
                                        onChange={(e) => setFieldValue(KEY_ADDRESS_LINE_2, e)}

                                    />
                                </div>
                            </div>
                        </div>
                        {
                            values[KEY_ADDRESS_COUNTRY] !== 'HK' &&
                            <div className={checkoutStyles.inputContainer}>
                                <span className={checkoutStyles.inputLabel}>Zip Code</span>
                                <div className={checkoutStyles.inputWrapper}>
                                    <div style={{flex: '2 1 0%'}}>
                                        <TextInput
                                            disabled={disableChangeCountryCode}
                                            readonly={isLoading}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors[KEY_ADDRESS_ZIP_CODE] && touched[KEY_ADDRESS_ZIP_CODE]}
                                            placeholder="Enter your postal code" value={values[KEY_ADDRESS_ZIP_CODE]}
                                            maxLength={20}
                                            onChange={(e) => setFieldValue(KEY_ADDRESS_ZIP_CODE, e)}
                                        />
                                    </div>
                                    {
                                        countryCode === US_COUNTRY_CODE &&
                                        <button className={!(disableChangeCountryCode) ? styles.findAddress : styles.findAddressDisabled}
                                                onClick={async () => {
                                                    await applyButtonClick();
                                                }}>
                                            Apply
                                        </button>
                                    }
                                </div>
                                {
                                    (errors[KEY_ADDRESS_ZIP_CODE] && touched[KEY_ADDRESS_ZIP_CODE]) &&
                                    <div>
                                        <InputError message={errors[KEY_ADDRESS_ZIP_CODE]}/>
                                    </div>
                                }
                            </div>
                        }
                        <div className={checkoutStyles.cityInputContainer}>
                            <div style={{width: '100%'}}>
                                <div style={{flex: '1 1', marginRight: 7}}>
                                    <span className={checkoutStyles.inputLabel}>City</span>
                                    <div style={{width: '100%', flexDirection: 'column', alignItems: 'start'}} className={checkoutStyles.inputWrapper}>
                                        <TextInput
                                            readonly={isLoading}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors[KEY_ADDRESS_CITY] && touched[KEY_ADDRESS_CITY]}
                                            value={values[KEY_ADDRESS_CITY]}
                                            placeholder="Enter your city"
                                            maxLength={20}
                                            onChange={(e) => setFieldValue(KEY_ADDRESS_CITY, e)}
                                        />
                                        <div>
                                            {
                                                (errors[KEY_ADDRESS_CITY] && touched[KEY_ADDRESS_CITY]) &&
                                                <InputError message={errors[KEY_ADDRESS_CITY]}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '100%'}}>
                                <div style={{flex: '1 1'}}>
                                    <span className={checkoutStyles.inputLabel}>{stateTitle}</span>
                                    <div style={{width: '100%', flexDirection: 'column', alignItems: 'start'}} className={checkoutStyles.inputWrapper}>
                                        <TextInput
                                            readonly={isLoading}
                                            onEnter={e => findNextInputAndFocus(e.target)}
                                            error={errors[KEY_ADDRESS_STATE] && touched[KEY_ADDRESS_STATE]}
                                            placeholder={statePlaceHolder}
                                            value={values[KEY_ADDRESS_STATE]}
                                            maxLength={20}
                                            onChange={(e) => setFieldValue(KEY_ADDRESS_STATE, e)}
                                        />
                                        {
                                            (errors[KEY_ADDRESS_STATE] && touched[KEY_ADDRESS_STATE]) &&
                                            <InputError message={errors[KEY_ADDRESS_STATE]}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        {
                            user && values[KEY_ADDRESS_ID] && values[KEY_ADDRESS_ID] === user.default_address_id &&
                            <div className={styles.titleContainer}>
                                <div className={styles.defaultAddressContainer}>
                                    <span className={styles.defaultAddressMark}>Default</span>
                                </div>
                            </div>
                        }
                        <div className={styles.nameContainer}>
                            <span
                                className={classNames(styles.receiver, styles.receiverName)}>{values[KEY_OVERSEAS_LAST_NAME]}, {values[KEY_OVERSEAS_FIRST_NAME]}</span>
                            <PipeIcon className={styles.pipe}/>
                            {
                                values[KEY_OVERSEAS_PHONE_COUNTRY_CODE] &&
                            <span className={styles.receiver}>({values[KEY_OVERSEAS_PHONE_COUNTRY_CODE]}) &nbsp;</span>
                            }
                            <span className={styles.receiver}>{values[KEY_OVERSEAS_PHONE_NUMBER]}</span>
                        </div>
                        <span className={styles.address}
                              style={{marginBottom: 16}}>({values[KEY_ADDRESS_ZIP_CODE]}) {values[KEY_ADDRESS_LINE_1]} {values[KEY_ADDRESS_LINE_2]}</span>
                    </>
            }
        </div>
    );
}
