import {isApp} from "common/utils";
import {Axios} from "api";
import {captureException} from "@sentry/nextjs";
import Cookies from "js-cookie";

export const getHmkCidInfo = () => {
    const serializedLocalHmkCidRecord = window.localStorage.getItem('hmkcid');
    let localHmkCidRecord = {};
    try {
        localHmkCidRecord = serializedLocalHmkCidRecord ? JSON.parse(serializedLocalHmkCidRecord) : {};
    } catch (e) {
        window.localStorage.removeItem('hmkcid');
    }
    const localHmkCid = localHmkCidRecord.hmkCid ?? null;
    const localHmkCidTimestamp = localHmkCidRecord.timestamp ?? null;
    const isLocalHmkCidMoreThanOneDay = localHmkCidTimestamp ?
        (Date.now() - localHmkCidTimestamp) > 24 * 60 * 60 * 1000 : false;
    const hmkCidHistory = localHmkCidRecord.history ?? [];

    const sessionHmkCidRecord = window.sessionStorage.getItem('hmkcid');
    let sessionHmkCid = null;
    let sessionHmkCidTimestamp = null;
    try {
        sessionHmkCid = sessionHmkCidRecord ? JSON.parse(sessionHmkCidRecord).hmkCid : null;
        sessionHmkCidTimestamp = sessionHmkCidRecord ? JSON.parse(sessionHmkCidRecord).timestamp : null;
    } catch (e) {
        window.sessionStorage.removeItem('hmkcid');
    }
    const isSessionHmkCidMoreThanOneDay = sessionHmkCidTimestamp ?
        (Date.now() - sessionHmkCidTimestamp) > 24 * 60 * 60 * 1000 : false;

    const hmkpid = getHmkpid();

    return {
        localHmkCid,
        sessionHmkCid,
        localHmkCidTimestamp,
        sessionHmkCidTimestamp,
        isLocalHmkCidMoreThanOneDay,
        isSessionHmkCidMoreThanOneDay,
        hmkCidHistory,
        hmkpid,
    };
};

export const setHmkCidInfo = (record) => {
    const serializedLocalHmkCidRecord = window.localStorage.getItem('hmkcid');
    let localHmkCidRecord = {};
    try {
        localHmkCidRecord = serializedLocalHmkCidRecord ? JSON.parse(serializedLocalHmkCidRecord) : {};
    } catch (e) {
        window.localStorage.removeItem('hmkcid');
    }

    let localHmkCidRecordHistory = localHmkCidRecord.history || [];
    if (localHmkCidRecord.hmkCid !== record.hmkCid || localHmkCidRecord.timestamp + 60 * 30 * 1000 < record.timestamp) {
        localHmkCidRecordHistory.splice(0, 0, record);
        localHmkCidRecordHistory = localHmkCidRecordHistory.slice(0, 100);
        localHmkCidRecord.hmkCid = record.hmkCid;
        localHmkCidRecord.timestamp = record.timestamp;
        localHmkCidRecord.history = localHmkCidRecordHistory;
    } else {
        record.timestamp = localHmkCidRecord.timestamp;
    }
    const localHmkCid = localHmkCidRecord.hmkCid;
    const localHmkCidTimestamp = localHmkCidRecord.timestamp;
    const isLocalHmkCidMoreThanOneDay = localHmkCidTimestamp ?
        (Date.now() - localHmkCidTimestamp) > 24 * 60 * 60 * 1000 : false;
    const hmkCidHistory = localHmkCidRecordHistory;

    const sessionHmkCid = record.hmkCid;
    const sessionHmkCidTimestamp = record.timestamp;
    const isSessionHmkCidMoreThanOneDay = false;

    window.localStorage.setItem('hmkcid', JSON.stringify(localHmkCidRecord));
    window.sessionStorage.setItem('hmkcid', JSON.stringify(record));

    return {
        localHmkCid,
        sessionHmkCid,
        localHmkCidTimestamp,
        sessionHmkCidTimestamp,
        isLocalHmkCidMoreThanOneDay,
        isSessionHmkCidMoreThanOneDay,
        hmkCidHistory,
    };
};

const getBrowseRecords = () => {
    const serializedRecords = window.localStorage.getItem('browse_records');
    let records = [];
    try {
        records = serializedRecords ? JSON.parse(serializedRecords) : [];
    } catch (e) {
        window.localStorage.removeItem('browse_records');
    }
    return records;
};

export const setHmkpid = async (hmkpid) => {
    window.localStorage.setItem('hmkpid', hmkpid);
    const records = getBrowseRecords();
    records.forEach(record => {
        record.hmkpid = hmkpid;
    });
    window.localStorage.setItem('browse_records', JSON.stringify(records));
};

export const getHmkpid = () => {
    let hmkpid = window.localStorage.getItem('hmkpid');
    if (!Boolean(hmkpid)) {
        const now = Date.now();
        hmkpid = `hmk_${now}_${Math.random().toString(36).substring(2, 4)}`;
        window.localStorage.setItem('hmkpid', hmkpid);
    }
    return hmkpid;
};

export const recordBrowseInfo = () => {
    if (typeof window === 'undefined') {
        return;
    }
    const params = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const isBrandPage = path?.endsWith('/store/brands');
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    const utmContent = params.get('utm_content');
    const utmTerm = params.get('utm_term');
    const hmkcid = params.get('hmkcid') || (isBrandPage ? null : params.get('c'));
    const hmkpid = getHmkpid();

    const timestamp = Date.now();

    const fbc = Cookies.get('_fbc');
    const fbp = Cookies.get('_fbp');
    const is_app = isApp();

    const record = {
        hmkpid,
        hmkcid,
        path,
        fbc,
        fbp,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm,
        timestamp,
        is_app,
    };

    const records = getBrowseRecords();
    records.push(record);
    window.localStorage.setItem('browse_records', JSON.stringify(records));

    sendBrowseRecords();
};

let browseRecordExecRef = null;

export const sendBrowseRecords = async () => {
    if (browseRecordExecRef) {
        clearTimeout(browseRecordExecRef);
        browseRecordExecRef = null;
    }
    browseRecordExecRef = setTimeout(async () => {
        if (typeof window === 'undefined') {
            return;
        }

        const records = getBrowseRecords();
        const data = {
            records,
        }

        if (records.length === 0) {
            return;
        }

        try {
            console.log('sendBrowseRecords', records);
            const res = await Axios.post(
                'v1/browse',
                data,
            );
            if (res.status < 400) {
                const newRecords = getBrowseRecords();
                const prevRecordTimestamps = records.map(record => record.timestamp);
                const filteredRecords = newRecords.filter(
                    record => !prevRecordTimestamps.includes(record.timestamp)
                );
                window.localStorage.setItem('browse_records', JSON.stringify(filteredRecords))
            } else {
            }
        } catch (e) {
            captureException(e);
        }
    }, 3000);
};

export const registerHmkCid = (hmkCid) => {
    const time = Date.now();
    const hmkcidRecord = {
        hmkCid,
        timestamp: time,
    };
    const record = setHmkCidInfo(hmkcidRecord);
    typeof mixpanel !== 'undefined' && mixpanel.register(record);
    console.log('hmkCidInfo', record);
};
