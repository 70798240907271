import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import useTranslation from "next-translate/useTranslation";
import styles from "components/cards/coupon/promotionAndCouponCard.module.scss";
import FullButton from "components/buttons/FullButton";
import TextInput from "components/input/TextInput";
import CloseIcon from "images/icons/close.svg";
import {Axios} from "api";
import {toast} from "react-toastify";
import {useContext, useEffect, useState} from "react";
import {captureException} from "@sentry/nextjs";
import Cookies from "js-cookie";
import referralContext from "context/ReferralContext";

export const handleDownloadPromotionCode = async (moldKey, t) => {
    try {
        const res = await Axios.post('v1/coupons/download', {
            mold_key: moldKey,
        });

        if (res.status < 400) {
            return res.data;
        } else {
            toast.info(res.data?.display_message || t('DownloadableCouponCard.errorMessage'));
        }
    } catch (e) {
        captureException(e);
        toast.info(t('DownloadableCouponCard.errorMessage'));
    }

    return null;
};

export const PromotionAndCouponCard = (props) => {
    const {order, selectedCoupons, removeCoupon, onSubmit, availableDoubleDiscountCoupons} = props;
    const {setGlobalReferralCode} = useContext(referralContext);
    const {t} = useTranslation('components-cards-promotionAndCouponCard');
    const isReservation = order.order?.is_reservation;
    const [couponCode, setCouponCode] = useState('');

    const submit = onSubmit || function () {
    };

    const placeHolderText = isReservation ?
        t('CheckoutCouponInfoSection.hemeticketNoCoupon') :
        t('CheckoutCouponInfoSection.inputCode');

    const anyCouponSelected = selectedCoupons?.length > 0;
    const isSelectableCoupon = !anyCouponSelected && !isReservation;

    const onSuccessfulCouponDownload = (coupon) => {
        if (coupon?.referral_code)  {
            setGlobalReferralCode(coupon?.referral_code);
        }
    }

    const applyCoupon = async (code) => {
        const coupon = availableDoubleDiscountCoupons?.filter(coupon => coupon?.coupon_mold_key === code);

        if (coupon?.length > 0) {
            submit(coupon[0], onSuccessfulCouponDownload);
        } else {
            downloadPromotionCode(code);
        }
    }

    const downloadPromotionCode = async (code) => {
        const ret = await handleDownloadPromotionCode(code, t)

        if (ret) {
            const coupon = ret?.coupons?.find(coupon => coupon?.coupon_mold_key === code);
            if (coupon) {
                submit(coupon, onSuccessfulCouponDownload);
                toast.info(t('PromotionCard.couponApplied'));
            } else {
                toast.info(t('PromotionCard.errorMessage'));
            }
        }
    }

    useEffect(() => {
        if (Boolean(selectedCoupons) && selectedCoupons.length > 0) {
            setCouponCode('');
        }
    }, [selectedCoupons])

    return (
        <div className={classNames(utilStyles.flexCol)}>
            <div className={classNames(utilStyles.flexRow)}>
                <TextInput placeholder={placeHolderText}
                           onChange = {(e) => setCouponCode(e)}
                           value={couponCode} disabled={!isSelectableCoupon}/>
                {
                    <div className={styles.button}>
                        <FullButton
                            fontWeight={600}
                            disabled={!isSelectableCoupon || couponCode?.length === 0}
                            onClick={() => {
                                applyCoupon(couponCode);
                            }}
                            height={44}
                            lightWhite={!isSelectableCoupon || couponCode?.length === 0}
                            width={97}
                            fontSize={15}
                            title={t('CheckoutCouponInfoSection.apply')}
                        />
                    </div>
                }
            </div>
            {
                selectedCoupons?.length > 0 && selectedCoupons.map(coupon => {
                    return (
                        <div key={`selected-coupon-${coupon.id}`} className={styles.couponInformationRow} onClick={() => {
                        }}>
                            <span className={styles.couponInformationDescription}>{coupon.name}</span>
                            <CloseIcon className={utilStyles.cursorPointer} viewBox="0 0 20 20" width={20} height={20}
                                       onClick={() => {
                                           removeCoupon(coupon)
                                       }}/>
                        </div>
                    )
                })
            }
        </div>
    )
}
